import * as React from "react";
import {ThemeProvider} from "@material-ui/core";
import {RootDataAttributesProps, RootDataAttributesProvider} from "./RootDataAttributes";
import {createTheme} from "@bp/benefit-plus-mobile/src/theme";

interface Props extends RootDataAttributesProps {

}

export class BasicProvider extends React.Component<Props> {
    render() {
        return (
            <ThemeProvider theme={createTheme()}>
                <RootDataAttributesProvider root={this.props.root}>
                    {this.props.children}
                </RootDataAttributesProvider>
            </ThemeProvider>
        );
    }
}
