import {LocalStorage} from "@bp/core-dependency/src/misc/LocalStorage";

import {LoginCredentials} from "../types/LoginCredentials";

export interface CoreStorage {
    usingBiometrics: string;
    "auto-login-credentials": LoginCredentials;
    versionDate: string;
    nativePIN: string;
    nativeAuth: string;
    didLogout: boolean;
    hideDownloadNativeAppSuggestion: boolean;
}

export const coreStorage = new LocalStorage<CoreStorage>();
