import {Assets as BaseAssets} from "@bp/benefit-plus-core/src/Assets";

import IMG_APPSTORE_DOWNLOAD from "./static/img/appstore_download.png";
import IMG_BACK_TO_TOP from "./static/img/back-to-top-arrow.svg";
import IMG_GOOGLE_PLAY_DOWNLOAD from "./static/img/google_play_download.png";
import IMG_LOCATION from "./static/img/illustration_location.svg";
import IMG_LOGO_SQUARE from "./static/img/logo.png";
import IMG_TIP1 from "./static/img/tip1.jpg";
import IMG_TIP2 from "./static/img/tip2.jpg";

export class Assets extends BaseAssets {
    public static readonly IMG_TIP1: string = IMG_TIP1;
    public static readonly IMG_TIP2: string = IMG_TIP2;
    public static readonly IMG_LOCATION: string = IMG_LOCATION;
    public static readonly IMG_GOOGLE_PLAY_DOWNLOAD: string = IMG_GOOGLE_PLAY_DOWNLOAD;
    public static readonly IMG_APPSTORE_DOWNLOAD: string = IMG_APPSTORE_DOWNLOAD;
    public static readonly IMG_BACK_TO_TOP: string = IMG_BACK_TO_TOP;
    public static readonly IMG_LOGO_SQUARE: string = IMG_LOGO_SQUARE;
}
