import * as React from "react";

const ctx = React.createContext<Record<string, number | string | undefined>>({});

interface Props {
    root: HTMLElement;
}

export type RootDataAttributesProps = Props;

export class RootDataAttributesProvider extends React.Component<Props> {
    render() {
        const {Provider} = ctx;
        return (
            <Provider value={this.props.root.dataset}>{this.props.children}</Provider>
        );
    }
}

export const RootDataAttributesConsumer = ctx.Consumer;
