import actionCreatorFactory, {ActionCreator as AC} from "typescript-fsa";

import {ResourceState} from "../../../types/ResourceState";
import {ReducerActionMap} from "../../reducers/ReducerActionMap";
import {isSagaDynamicReducer} from "../../reducers/types/ISagaDynamicReducer";
import {DynamicReducerType, isAsyncDynamicReducer} from "../../reducers/types/Types";
import {Take} from "../../sagas/helpers";
import {SagaReducerMap} from "../../sagas/SagaReducerMap";
import {
    asyncActionCreator,
    AsyncActionCreatorType,
    isAsyncAction,
    ResponsePayloadType,
} from "../asyncActionCreator";
import {CreatedActionMap} from "../CreatedActionMap";

const createAction = actionCreatorFactory();
let productionId = 0;

export type Options = Partial<{
    take: Take;
}>;

export const ActionCreator = <S extends ResourceState, RQ, RS>(opts: Options = {}) => (Reducer: {
    new (): DynamicReducerType<S, RQ, RS>;
}) => {
    const type = process.env.NODE_ENV === "production" ? `action_${productionId++}` : Reducer.name;
    Reducer.prototype.type = type;
    const reducer = new Reducer();
    const actionCreator: AC<RQ> | AsyncActionCreatorType<RQ, RS> = isAsyncDynamicReducer(reducer)
        ? ((asyncActionCreator<RS, RQ>(type) as unknown) as AsyncActionCreatorType<RQ, RS>)
        : createAction<RQ>(type);
    CreatedActionMap.put(type, actionCreator);
    if (isSagaDynamicReducer(reducer) && isAsyncAction(actionCreator)) {
        SagaReducerMap.addSagaReducer(actionCreator, reducer, opts.take || Take.Latest);
    }
    ReducerActionMap.addReducer<S, RQ, RS>(actionCreator, reducer as any);
};
