import * as React from "react";
import * as ReactDOM from "react-dom";
import {createStyles, Theme, withStyles} from "@material-ui/core";
import {WithStyles} from "@bp/core-dependency/src/types/withStyles";
import {RootDataAttributesConsumer} from "../Common/RootDataAttributes";

interface Props extends WithStyles<typeof styles> {
}

interface State {
}

const styles = (theme: Theme) => createStyles({});

@(withStyles as any)(styles)
export class MetaIosTag extends React.Component<Props, State> {

    render() {
        return ReactDOM.createPortal(
            <RootDataAttributesConsumer>
                {({iosAppId}) => (
                    <meta name="apple-itunes-app" content={`app-id=${iosAppId || 772061221}`}/>
                )}
            </RootDataAttributesConsumer>,
            document.head
        );
    }
}
