import * as _ from "lodash";

export type Options = {temporary?: boolean};

export class LocalStorage<I extends {}> {
    private static storage(options: Options) {
        return options.temporary ? sessionStorage : localStorage;
    }

    getItem<T extends I[K], K extends keyof I = keyof I>(
        key: K,
        def?: T,
        options: Options = {}
    ): T | undefined {
        const value = LocalStorage.storage(options).getItem(key as string);
        if (!value || value === "undefined") {
            return def;
        }
        return JSON.parse(value);
    }

    setItem<T extends I[K], K extends keyof I>(key: K, value: T, options: Options = {}): void {
        if (value === undefined || value === null) {
            this.removeItem(key);
        } else {
            LocalStorage.storage(options).setItem(key as string, JSON.stringify(value));
        }
    }

    updateItem<E extends I[K], T, K extends keyof I>(
        key: K,
        path: _.PropertyPath,
        value: T,
        options: Options = {}
    ): void {
        const item: E = this.getItem<E, K>(key);
        this.setItem(key, _.set<E>(item as any, path, value));
    }

    removeItem<T>(key: keyof I, options: Options = {}): boolean {
        const exists = !!LocalStorage.storage(options).getItem(key as string);
        LocalStorage.storage(options).removeItem(key as string);
        return exists;
    }

    clear(options: Options = {}) {
        LocalStorage.storage(options).clear();
    }

    keys(options: Options = {}): string[] {
        return Object.keys(LocalStorage.storage(options));
    }
}
