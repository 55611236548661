import {AppLang} from "@bp/core-dependency/src/localization/AppLang";

import ADD_TO_HOMESCREEN_ADD from "./static/img/addToHomescreen/add.svg";
import ADD_TO_HOMESCREEN_CLOSE from "./static/img/addToHomescreen/close.svg";
import ADD_TO_HOMESCREEN_SHARE from "./static/img/addToHomescreen/share.svg";
import APP_GALLERY_BADGE from "./static/img/app-gallery.png";
import IMG_BP_LOGO_FILLED from "./static/img/bp_logo_filled.png";
import IMG_LOGO from "./static/img/bp_logo_white.svg";
import IMG_CARD1 from "./static/img/card_blank.png";
import IMG_CARD1_EAN from "./static/img/card1ean.png";
import IMG_MAP_CLUSTER from "./static/img/map_marketplace_cluster.svg";
import IMG_MAP_MY_LOCATION from "./static/img/map_marketplace_my_location.svg";
import NO_CARD from "./static/img/no_card.svg";
import IMG_SHOPPING_CART_SENT from "./static/img/shopping_cart_sent.svg";
import {createLocalizedAsset} from "./helpers/CreateLocalizedAsset";
import {default as Card, ReactComponent as CardCmp} from "./static/img/card.svg";
import {
    default as NoResultsFoundStr,
    ReactComponent as NoResultsFoundCmp,
} from "./static/img/illustration_no-results.svg";
import {
    default as AppStoreBadgeCS,
    ReactComponent as AppStoreBadgeComponentCS,
} from "./static/img/localized/app-store/app-store-cs.svg";
import {
    default as AppStoreBadgeEN,
    ReactComponent as AppStoreBadgeComponentEN,
} from "./static/img/localized/app-store/app-store-en.svg";
import {
    default as AppStoreBadgeSK,
    ReactComponent as AppStoreBadgeComponentSK,
} from "./static/img/localized/app-store/app-store-sk.svg";
import {
    default as GooglePlayBadgeCS,
    ReactComponent as GooglePlayBadgeComponentCS,
} from "./static/img/localized/play-store/google-play-badge-cs.svg";
import {
    default as GooglePlayBadgeEN,
    ReactComponent as GooglePlayBadgeComponentEN,
} from "./static/img/localized/play-store/google-play-badge-en.svg";
import {
    default as GooglePlayBadgeSK,
    ReactComponent as GooglePlayBadgeComponentSK,
} from "./static/img/localized/play-store/google-play-badge-sk.svg";
import {
    default as FindMyLocation,
    ReactComponent as FindMyLocationCmp,
} from "./static/img/map_marketplace_find_my_location.svg";
import {default as NoCard, ReactComponent as NoCardCmp} from "./static/img/no-card.svg";
import {default as Pin, ReactComponent as PinCmp} from "./static/img/pin.svg";
import {default as StarActive, ReactComponent as StarActiveCmp} from "./static/img/star-active.svg";
import {default as Star, ReactComponent as StarCmp} from "./static/img/star.svg";
import {AssetSvg} from "./types/AssetSvg";
import {LocalizedAssetSvg} from "./types/LocalizedAssetSvg";

const IMG_MAP_CLUSTER_ENCODED = (color: string, text: string = "", shadow: boolean = true) =>
    `data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%225%205%2090%2090%22%20class%3D%22injected-svg%22%20data-src%3D%22%2Fstatic%2Fmedia%2Fmap_marketplace_cluster.07dccbf4.svg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%3Cfilter%20id%3D%22shadow%22%20height%3D%22130%25%22%3E%0A%20%20%3CfeGaussianBlur%20in%3D%22SourceAlpha%22%20stdDeviation%3D%223%22%3E%3C%2FfeGaussianBlur%3E%20%3C!--%20stdDeviation%20is%20how%20much%20to%20blur%20--%3E%0A%20%20%3CfeOffset%20dx%3D%222%22%20dy%3D%222%22%20result%3D%22offsetblur%22%3E%3C%2FfeOffset%3E%20%3C!--%20how%20much%20to%20offset%20--%3E%0A%20%20%3CfeComponentTransfer%3E%0A%20%20%20%20%3CfeFuncA%20type%3D%22linear%22%20slope%3D%220.4%22%3E%3C%2FfeFuncA%3E%20%3C!--%20slope%20is%20the%20opacity%20of%20the%20shadow%20--%3E%0A%20%20%3C%2FfeComponentTransfer%3E%0A%20%20%3CfeMerge%3E%20%0A%20%20%20%20%3CfeMergeNode%3E%3C%2FfeMergeNode%3E%20%3C!--%20this%20contains%20the%20offset%20blurred%20image%20--%3E%0A%20%20%20%20%3CfeMergeNode%20in%3D%22SourceGraphic%22%3E%3C%2FfeMergeNode%3E%20%3C!--%20this%20contains%20the%20element%20that%20the%20filter%20is%20applied%20to%20--%3E%0A%20%20%3C%2FfeMerge%3E%0A%3C%2Ffilter%3E%0A%3Ccircle%20cx%3D%2249.5%22%20cy%3D%2250%22%20r%3D%2240%22%20fill%3D%22%23${color.substr(
        1
    )}%22%20style%3D%22${
        shadow ? "filter%3Aurl(%23shadow)%3B" : ""
    }%22%3E%3C%2Fcircle%3E%0A%0A${text}%3C%2Fsvg%3E`;

export class Assets {
    public static readonly IMG_LOGO: string = IMG_LOGO;
    public static readonly IMG_BP_LOGO_FILLED: string = IMG_BP_LOGO_FILLED;
    public static readonly ADD_TO_HOMESCREEN_CLOSE: string = ADD_TO_HOMESCREEN_CLOSE;
    public static readonly ADD_TO_HOMESCREEN_SHARE: string = ADD_TO_HOMESCREEN_SHARE;
    public static readonly ADD_TO_HOMESCREEN_ADD: string = ADD_TO_HOMESCREEN_ADD;
    public static readonly IMG_MAP_MY_LOCATION: string = IMG_MAP_MY_LOCATION;
    public static readonly IMG_MAP_CLUSTER: string = IMG_MAP_CLUSTER;
    public static readonly IMG_SHOPPING_CART_SENT: string = IMG_SHOPPING_CART_SENT;
    public static readonly IMG_MAP_CLUSTER_ENCODED: (
        color: string,
        text?: string,
        shadow?: boolean
    ) => string = IMG_MAP_CLUSTER_ENCODED;
    public static readonly IMG_CARD1_EAN: string = IMG_CARD1_EAN;
    public static readonly IMG_CARD1: string = IMG_CARD1;
    public static readonly SVG_STAR: AssetSvg = {
        src: Star,
        Component: StarCmp,
    };
    public static readonly SVG_STAR_ACTIVE: AssetSvg = {
        src: StarActive,
        Component: StarActiveCmp,
    };
    public static readonly SVG_NO_RESULTS_FOUND: AssetSvg = {
        src: NoResultsFoundStr,
        Component: NoResultsFoundCmp,
    };
    public static readonly NO_CARD: string = NO_CARD;
    public static readonly SVG_PAYMENT_CARD: AssetSvg = {
        src: Card,
        Component: CardCmp,
    };
    public static readonly SVG_PAYMENT_NO_CARD: AssetSvg = {
        src: NoCard,
        Component: NoCardCmp,
    };
    public static readonly SVG_PIN: AssetSvg = {
        src: Pin,
        Component: PinCmp,
    };
    public static readonly SVG_FIND_MY_LOCATION: AssetSvg = {
        src: FindMyLocation,
        Component: FindMyLocationCmp,
    };

    public static readonly SVG_PLAY_STORE_BADGE: LocalizedAssetSvg = createLocalizedAsset({
        "cs-CZ": [GooglePlayBadgeCS, GooglePlayBadgeComponentCS],
        "en-US": [GooglePlayBadgeEN, GooglePlayBadgeComponentEN],
        "sk-SK": [GooglePlayBadgeSK, GooglePlayBadgeComponentSK],
    });

    public static readonly SVG_APP_STORE_BADGE: LocalizedAssetSvg = createLocalizedAsset({
        "cs-CZ": [AppStoreBadgeCS, AppStoreBadgeComponentCS],
        "en-US": [AppStoreBadgeEN, AppStoreBadgeComponentEN],
        "sk-SK": [AppStoreBadgeSK, AppStoreBadgeComponentSK],
    });

    public static readonly APP_GALLERY_BADGE: string = APP_GALLERY_BADGE;
}

//Svg def ids for SVGDefsComponent
export class SVGDefs {
    public static readonly BG_GRADIENT: string = "BG_GRADIENT";
}
