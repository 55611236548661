export default {
    "Změny byly uloženy.": "Zmeny boli uložené.",
    "Více": "Viac",
    "Vítejte": "Vitajte",
    "Nápověda": "Nápomoc",
    "Ochrana osobních údajů": "Ochrana osobných údajov",

    "Polohové služby": "Služby GPS",
    "Povolte nám přístup k Vaší poloze, abychom mohli zobrazovat benefity nejblíže k Vám.":
        "Povoľte nám prístup k Vašej polohe, aby sme Vám mohli zobrazovať benefity, ktoré sú najbližšie k Vám.",
    "Máte {1} nové zprávy.": {phrase: "Máte {1} nové správy.", 1: ""},
    "Uživatelské jméno": "Užívateľské meno",
    "Heslo": "Heslo",
    "Přihlásit se": "Prihlásiť sa",
    "Zapomněli jste heslo?": "Zabudli ste heslo?",
    "Zaslat nové heslo": "Zaslať nové heslo?",
    "Zpět na přihlášení": "Späť na prihlásenie",
    "Chcete si uložit {1} na Vaše zařízení?": {phrase: "Chcete si uložit {1} na Vaše zariadenie?", 1: ""},
    "Stiskněte": "Stlačte",
    "a poté": "následne",
    "(Přidat na plochu)": "(Pridať na plochu)",
    "Kartou lze zaplatit": "Platba kartou je možná",
    "Karta Benefit Plus": "Karta Benefit Plus",
    "Aktivovat kartu": "Aktivovať kartu",
    "Zablokovat kartu": "Zablokovať kartu",
    "Adresa": "Adresa",
    "Email": "Email",
    "Mapa": "Mapa",
    "Zobrazit mapu": "Zobraziť mapu",
    "Telefon": "Telefón",
    "Web": "Web",
    "Popis": "Popis",
    "Kontakt": "Kontakt",
    "Objednat": "Objednať",
    "Minimální částka pro objednávku je {1}": {phrase: "Minimálna čiastka pre objednávku je {1}", 1: ""},
    "Maximální částka pro objednávku je {1}": {phrase: "Maximálna čiastka pro objednávku je {1}", 1: ""},
    "Objednat benefit": "Objednať benefit",
    "Hodnota benefitu": "Hodnota benefitu",
    "Kč": "EUR",
    "Bodová hodnota": "Bodová hodnota",
    "Bodový koeficient": "Bodový koeficient",
    "1 Kč = 1,00 bodu": "1 EURO = 1,00 bod",
    "bodů": "bodov",
    "Zůstatek peněženky": "Zostatok peňaženky",
    "Poznámka k objednávce": "Poznámka k objednávke",
    "Tipy pro vás": "Tipy pre Vás",
    "Vyhledávání": "Vyhľadávanie",
    "Vaše poloha": "Vaša poloha",
    "Chyba při zjištění polohy: [{1}] {2}": {phrase: "Chyba pri zisťovaní polohy: [{1}] {2}", 1: "", 2: ""},
    "Rozpis platby": "Rozpis platby",
    "V mém okolí": "V mojom okolí",
    "peněženka má": "peňaženka má",
    "peněženky mají": "peňaženky majú",
    "Následující peněženky mají nedostatečný zůstatek pro provedení platby – {1}": {
        phrase: "Následujúce peňaženky majú nedostatočný zostatok pre realizovanie platby – {1}",
        1: "",
    },
    "Historie transakcí": "História transakcií",
    "Celková hodnota benefitů k objednání:": "Celková hodnota benefitu pre objednanie:",
    "Váš košík je zatím prázdný.": "Váš košík je zatiaľ prázdny.",
    "Košík": "Košík",
    "Objednávka odeslána": "Objednávka bola odoslaná",
    "Děkujeme za objednávku, odeslali jsme ji na Váš email.":
        "Ďakujeme za Vašu objednávku, odoslali sme ju na Váš mail.",
    "Celkem ({1} položek)": {phrase: "Celkom ({1} položiek)", 1: ""},
    "Kontakt na Benefit Plus": "Kontakt na Benefit Plus",
    "Odhlásit se": "Odhlásiť sa",
    "Pro změnu nastavení přejít do webové aplikace": "Pre zmenu nastavenia prejsť do webovej aplikácie",
    "Zákaznické centrum Benefit Plus": "Zákaznícke centrum Benefit Plus",
    "Potřebujete pomoci? Jsme tu pro Vás v pracovní dny od {1} do {2}.": {
        phrase: "Potrebujete pomôcť? Sme tu pre Vás v pracovných dňoch od {1} do {2}.",
        1: "",
        2: "",
    },
    "Expirace:": "Exspirácia:",
    "Zpět": "Späť",
    "Zrušit": "Zrušiť",
    "Smazat": "Zmazať",
    "Zavřít": "Zavrieť",
    "Info": "Info",
    "Menu": "Menu",
    "({1} hodnocení)": {phrase: "({1} hodnotenie)", 1: ""},
    "Pohled z ulice": "Pohľad z ulice",
    "Zůstatky": "Zostatky",
    "Neznámé uživatelské jméno nebo chybné heslo.": "Neznáme uživatelské meno alebo chybné heslo.",
    "Odkaz pro vygenerování nového hesla byl odeslán na Váš email.":
        "Odkaz pre vygenerovanie nového hesla bol odeslaný na Váš email.",
    "Zadané uživatelské jméno neexistuje": "Zadané uživatelské meno neexistuje",
    "Nastala chyba při resetování hesla": "Nastala chyba pri resetovaní hesla",
    "Přidáno do košíku.": "Pridané do košíka.",
    "Chyba při zpracování některé položky objednávky, zkuste odeslat košík prosím znovu.":
        "Chyba pri spracovaní niektorej položky objednávky, skúste odoslať košík znovu prosím.",
    "Chyba při odesílání objednávky, zkuste to prosím znovu.":
        "Chyba pri odosielaní objednávky, skúste to znovu prosím.",
    "Položka košíku {1} ({2}) byla zpracována": {
        phrase: "Položka košíka {1} ({2}) bola spracovaná",
        1: "",
        2: "",
    },
    "Položka košíku byla zpracována": "Položka košíka bola spracovaná",
    "Potvrdit objednávku": "Potvrdiť objednávku",
    "Vyberte možnost": "Vyberte možnosť",
    "Vyplňte prosím všechna pole označená hvězdičkou.": "Vyplňte prosím všetky polia označené hviezdičkou.",
    "roční": "ročný",
    "denní": "denný",
    "Objednání benefitu {1} v bodové hodnotě {2} by překročilo Váš {3} limit pro kategorii {4} o {5} bodů.": {
        phrase:
            "Objednanie benefitu {1} v bodovej hodnote {2} by prekročilo Váš {3} limit kategórie {4} o {5} bodov.",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
    },
    "Objednání benefitu {1} v bodové hodnotě {2} by překročilo Váš {3} limit pro subkategorii {4} o {5} bodů.": {
        phrase:
            "Objednanie benefitu {1} v bodovej hodnote {2} by prekročilo Váš {3} limit subkategórie {4} o {5} bodov.",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
    },
    "Objednání tohoto benefitu v bodové hodnotě {1} by překročilo Váš {2} limit pro kategorii {3} o {4} bodů.": {
        phrase:
            "Objednanie tohto benefitu v bodovej hodnote {1} by prekročilo Váš {2} limit kategórie {3} o {4} bodov.",
        1: "",
        2: "",
        3: "",
        4: "",
    },
    "Objednání tohoto benefitu v bodové hodnotě {1} by překročilo Váš {2} limit pro subkategorii {3} o {4} bodů.": {
        phrase:
            "Objednanie tohto benefitu v bodovej hodnote {1} by prekročilo Váš {2} limit subkategórie {3} o {4} bodov.",
        1: "",
        2: "",
        3: "",
        4: "",
    },
    "Přiložit soubor": "Priložiť súbor",
    "Soubor je moc veliký ({1} MB). Maximální velikost souboru je {2} MB": {
        phrase: "Súbor je moc veľký ({1} MB). Maximálna veľkosť súboru je {2} MB",
        1: "",
        2: "",
    },
    "Nepodporovaný typ souboru ({1}). Podporované typy jsou {2}.": {
        phrase: "Nepodporovaný typ súboru ({1}). Podporované typy sú {2}.",
        1: "",
        2: "",
    },
    "Zapamatovat přihlášení": "Zapamätať prihlásenie",
    "Vyhledat mezi transakcemi": "Vyhľadať medzi transakciami",
    "Smluvní partneři": "Zmluvní partneri",
    "Pro přihlášení jako Smluvní partner prosím stahujte k tomu určenou aplikaci Benefit Plus Partner.":
        "Pre prihlásenie ako Zmluvný partner prosím sťahujte na to určenú aplikáciu Benefit Plus Partner.",

    "Zaslat jako dárkový poukaz": "Zaslať ako darček",
    "Jméno a příjmení obdarovaného": "Meno a priezvisko obdarovaného",
    "Moje oblíbené": "Moje obľúbené",
    "Všechny podskupiny": "Všetky podskupiny",
    "Zobrazit na mapě": "Zobraziť na mape",

    "Seřadit dle adresy např. Vinohrady, Praha 2": "Zoradiť podľa adresy, napr. Vinohrady, Praha 2",
    "Ano": "Áno",
    "Ne": "Nie",
    "Recenze": "Recenzie",
    "dle {x} hodnocení": {
        phrase: "podľa {x} hodnotení",
        plurals: {
            "sg": "podľa {x} hodnotenia",
            "pl": "podľa {x} hodnotení",
        },
        x: 0,
    },
    "Dokumenty ke stažení": "Dokumenty na stiahnutie",

    "Můj profil": "Môj profil",
    "Karta benefit plus": "Karta benefit plus",
    "Soukromá bankovní karta": "Súkromná banková karta",
    "Změna hesla": "Zmena hesla",
    "Notifikace událostí": "Notifikácie udalostí",
    "Mohlo by vás také zajímat": "Mohlo by vás tiež zaujímať",
    "Jméno": "Meno",
    "Příjmení": "Priezvisko",
    "E-mail": "E-mail",
    "Jazyk aplikace": "Jazyk aplikácie",
    "Český": "Český",
    "Slovenský": "Slovenský",
    "Anglický": "Anglický",
    "Uložit údaje": "Uložit údaje",
    "Změnit heslo": "Zmeniť heslo",
    "Stávající heslo": "Súčasné heslo",
    "Nové heslo": "Nové heslo",
    "Potvrzení nového hesla": "Potvrdenie nového hesla",
    "Objednávka benefitu": "Objednávka benefitu",
    "Doplatit bankovní kartou": "Doplatiť bankovou kartou",
    "Detailní podmínky": "Detailné podmienky",
    "Souhlasím": "Súhlasím",
    "Odečteme Vám": {phrase: "Odčítame Vám: {1} {2}", 1: "", 2: ""},
    "Zaslat notifikace k následujícím modulům:": "Zaslať notifikácie k nasledujúcim modulom:",
    "Inovace": "Inovácie",
    "Pochvaly": "Pochvaly",
    "Bazar": "Bazár",
    "Spolky": "Spolky",
    "Zasílat newsletter BP (1x měsíčně)": "Zasielať newsletter BP (1x mesačne)",
    "Nesouhlasím": "Nesúhlasím",

    "Uplatnit práva pro ochranu osobních údajů": "Uplatniť práva na ochranu osobných údajov",
    "Námitka proti zpracování z důvodu oprávněných zájmů správce osobních údajů":
        "Námietka proti spracovaniu z dôvodu oprávnených záujmov správcu osobných údajov",
    "Uplatnění práva na výmaz osobních údajů": "Uplatnenie práva na výmaz osobných údajov",
    "Uplatnění práva na kopii osobních údajů": "Uplatnenie práva na kópiu osobných údajov",
    "Uplatnění práva na přenos osobních údajů": "Uplatnenie práva na prenos osobných údajov",
    "Uplatnění práva na opravu osobních údajů": "Uplatnenie práva na opravu osobných údajov",
    "Uplatnění práva na přístup k osobním údajům": "Uplatnenie práva na prístup k osobným údajov",
    "Uplatnění práva nebýt předmětem žádného rozhodnutí založeného výhradně na automatizovaném robotovi":
        "Uplatnenie práva nebyť predmetom žiadneho rozhodnutia založeného výhradne na automatizovanom robotovi",

    "Číslo karty": "Číslo karty",
    "Aktivace karty": "Aktivácia karty",
    "Kartou lze platit": "Možnosť platiť kartou",
    "Mobil pro kartu": "Mobil pre kartu",
    "Email pro kartu": "Email pre kartu",
    "Přidat druhý mobil pro kartu": "Pridať druhý mobil pre kartu",
    "Přidat druhý email pro kartu": "Pridať druhý email pre kartu",
    "Odblokovat kartu": "Odblokovať kartu",
    "Objednat náhradní kartu": "Objednať náhradnú kartu",
    "Načíst další": "Nahrať ďalšie",
    "Datum napárování k BP": "Dátum spárovania s BP",
    "Expirace karty": "Exspirácia karty",
    "Odebrat bankovní kartu": "Odobrať bankovú kartu",
    "Přidat bankovní kartu": "Pridať bankovú kartu",
    "Přečetl(a) jsem si ": "Prečítal(a) som si ",
    "Podmínky využití soukromé bankovní karty ": "Podmienky využitia súkromnej bankovej karty ",
    "a souhlasím s nimi.": "a súhlasím s nimi.",
    "Objednat a doplatit {1}": {phrase: "Objednať a doplatiť {1}", 1: ""},
    "Děkujeme za objednávku, poukázku jsme vám odeslali na e-mail. Nyní můžete přejít do historie transakcí a poukázku si vytisknout odeslat pomocí SMS na mobil.":
        'Ďakujeme za objednávku, poukážku sme vám odoslali na e-mail. Teraz môžete prejsť do histórie transakcií a poukážku si <a href="#print" data-no-href="true">vytlačiť</a> odoslať pomocou <a href="#SMS" data-no-href="true">SMS</a> na mobil.',
    "Zpět na košík": "Späť na košík",
    "Zobrazit": "Zobraziť",
    "Od": "Od",
    "Do": "Do",
    "Typ transakcí": "Typ transakcií",
    "Podmínky použití pro registrované bankovní karty":
        "Podmienky na škárovanie súkromné bankové karty s kartou Benefit Plus",
    "Podmínky použití bankovní karty pro jednorázové platby":
        "Podmienky jednorazového doplatku súkromnú bankovou kartou",
    "Podmínky pro spárování soukromé bankovní karty s kartou Benefit Plus":
        "Podmienky pre spárovanie súkromnej bankovej karty s kartou Benefit Plus",
    "Podmínky jednorázového doplatku soukromou bankovní kartou":
        "Podmienky jednorázového doplatku súkromnou bankovou kartou",
    "Rozpis platby: (hodnota objednávky bude odečtena z těchto účtů)":
        "Rozpis platby: (hodnota objednávky bude odpočítaná z týchto účtov)",
    "Přihlásit pomocí biometriky": "Prihlásiť pomocou biometrie",
    "Rozpoznání biometriky se nezdařilo": "Rozpoznanie biometrie zlyhalo",
    "Přihlášení se nezdařilo, nejprve povolte přihlášení biometrikou v nastavení profilu":
        "Prihlásenie sa nepodarilo, najprv povoľte prihlásenie biometria v nastavení profilu",
    "Použít biometriku": "Použiť biometria",
    "Zadejte heslo": "Zadajte heslo",
    "Potvrďte heslo": "Potvrďte heslo",
    "Neplatné heslo": "Neplatné heslo",
    "Zadaná hesla se neshodují": "Zadané heslá sa nezhodujú",
    "Pro ověření potvrďte heslo": "Pre overenie potvrďte heslo",
    "Pro použití biometriky, si prosím nejdříve nastavte heslo":
        "Pre použitie biometrie, si prosím najskôr nastavte heslo",
    "Nastavení": "Nastavenie",
    "Povolit Touch ID": "Povoliť Touch ID",
    "Povolit Face ID": "Povoliť Face ID",
    "Čtečka otisku prstů": "Čítačka odtlačkov prstov",
    "Přidáno do {1}": {phrase: "Pridané do {1}", 1: ""},
    "oblíbených": "obľúbených",
    "Odebráno z {1}": {phrase: "Vymazané z {1}", 1: ""},
    "Nástěnka": "Nástenka",
    "Transakce": "Transakcie",
    "Hledání": "Hľadanie",
    "Karta": "Karta",
    "Nenalezeny žádné výsledky": "Nenašli sa žiadne výsledky.",
    "Nenalezena žádná karta": "Nenájdená žiadna karta",

    "Kategorie": "Kategórie",
    "Filtrovat dle": "Filtrovať podľa",
    "Kartou BP lze zaplatit": "Lze zaplatit kartou Benefit Plus",
    "Kartou BP nelze zaplatit": "Nelze zaplatit kartou Benefit Plus",
    "Přidat do oblíbených": "Pridať do obľúbených",
    "Odebrat z oblíbených": "Odobrať z obľúbených",
    "Hledat např. Wellness, Restaurace...": "Hľadať napr. Wellness, Reštaurácie...",
    "Seznam benefitů": "Zoznam benefitov",
    "Zobrazení": "Zobrazenie",
    "Potvrdit": "Potvrdiť",
    "Všechny": "Všetky",
    "Informace": "Informácie",
    "Kontakty": "Kontakty",
    "Odhlásit": "Odhlásiť",
    "Přidat do {1}": {phrase: "Pridať do {1}", 1: ""},
    "Odebrat z {1}": {phrase: "Odstrániť z {1}", 1: ""},
    "Oblíbené": "Obľúbené",
    "SMS": "SMS",
    "Hodnocení": "Hodnotenie",
    "Navigace": "Navigácia",
    "Podskupiny": "Podskupiny",
    "Další filtry": "Ďalšie filtre",
    "Seřadit dle vzdálenosti od": "Zoradiť podľa vzdialenosti od",
    "Informace o obchodním místě": "Informácie o obchodnom mieste",
    "košíku": "košíka",
    "Vítejte v Cafeterii": "Vitajte v Cafetérii",
    'Aplikace Benefit Plus má několik různých modulů. Cafeterie slouží k nákupu daňově zvýhodněných služeb, za které platíte benefitovými body. Pokud jste zde poprvé, podívejte se jak provést objednávku na <a href="{1}" target="_blank">úvodním videu</a>, nebo v <a href="{2}"> Nápovědě</a>.': {
        phrase:
            'Aplikácia Benefit Plus má niekoľko rôznych modulov. Cafeteria slúži na nákup daňovo zvýhodnených služieb, za ktoré platíte benefitovými bodmi. Ak ste tu prvýkrát, pozrite sa ako vykonať objednávku na <a href="{1}" target="_blank">úvodnom videu</a>, alebo v <a href="{2}">časti Pomoc</a>.',
        1: "",
        2: "",
    },
    "Příště nezobrazovat": "Nabudúce nezobrazovať",
    "Odeslat pomocí SMS na telefon": "Odoslať pomocou SMS na telefón",
    "Stáhnout poukaz": "Stiahnuť poukaz",
    "SMS zpráva byla odeslána": "SMS správa bola odoslaná",
    "E-Stravenky": "E-Stravenky",
    "Text hodnocení": "Text hodnotenia",
    "Transakce byla ohodnocena.": "Transakcia bola ohodnotená.",
    "Seznam transakcí": "Zoznam transakcií",
    "Ohodnotit benefit": "Ohodnotiť benefit",
    "Pokračovat v nákupu": "Pokračovať v nákupe",
    "Stáhnout jako Excel tabulku": "Stiahnuť ako Excel tabuľku",
    "Název benefitu": "Názov benefitu",
    "Poskytovatel": "Poskytovateľ",
    "Město": "Mesto",
    "Karta BP": "Karta BP",
    "Přidat do košíku": "Pridať do košíka",
    "Zvolte položku": "Zvoľte položku",
    "Přidat soubor": "Pridať súbor",
    "Nové benefity": "Nové benefity",
    "Platba kartou BP": "Platba kartou BP",
    "Odečteme Vám {1}": {phrase: "Odčítame Vám {1}", 1: ""},
    "Aktivace karty se nezdařila": "Aktivácia karty zlyhala",
    "Aktualizace karty se nezdařila": "Aktualizácia údajov na karte zlyhala",
    "Blokace karty se nezdařila": "Blokácia karty zlyhala",
    "Deaktivace karty se nezdařila": "Deaktivácia karty zlyhala",
    "Objednávka karty se nezdařila": "Objednávka karty zlyhala",
    "Odblokování karty se nezdařilo": "Odblokovanie karty zlyhalo",
    "Nastavení notifikací se neuložilo": "Nastavenie notifikacií sa neuložilo",
    "Nastavení notifikací proběhlo v pořádku": "Nastavenie notifikácií prebehlo v poriadku",
    "Změna hesla se nezdařila": "Zmena hesla sa nepodarila",
    "Přidání avataru se nezdařilo": "Pridanie avataru sa nepodarilo",
    "Vymazání avataru se nezdařilo": "Vymazanie avataru sa nepodarilo",
    "Vymazání avataru bylo úspěšné": "Vymazanie avataru bolo úspešné",
    "Nahrát": "Nahrať",
    "Profil": "Profil",
    "Hlavní nastavení": "Hlavné nastavenia",
    "Ostatní nastavení": "Ostatné nastavenia",
    "Sdílet": "Zdieľať",
    "Hodnotit": "Hodnotiť",
    "Verze": "Verzia",
    "Nastavení jazyka": "Nastavenie jazyka",
    "Změna jazyka se nepodařila": "Zmena jazyka sa nepodarila",
    "Jazyk aplikace byl úspěšně změněn": "Jazyk aplikácie bol úspešne zmenený",
    "Vyhledávání dle fráze": "Vyhľadávanie podľa frázy",
    "Zde můžete prohledávat seznam benefitů dle Vámi vybraných kriterii.":
        "Tu môžete vyhľadávať zoznam benefitov podľa Vami vybraných kritérií.",
    "Nastala chyba při přihlášení": "Nastala chyba pri prihlásení",
    "Aktualizace profilu se nezdařila": "Aktualizácia profilu zlyhala",
    "Aktualizace profilu proběhla úspěšně": "Aktualizácia profilu prebehla úspešne",
    "Karta byla aktualizována": "Karta bola aktualizovaná",
    "Karta byla odblokována": "Karta bola odblokovaná",
    "Karta byla zablokována": "Karta bola zablokovaná",
    "Karta byla aktivována": "Karta bola aktivovaná",
    "Heslo bylo změněno": "Heslo bolo zmenené",
    "Skupiny a podskupiny": "Skupiny a podskupiny",
    "Karta byla odebrána": "Karta bola vymazaná",
    "Odebrání karty se nezdařilo": "Vymazanie karty sa nepodarilo",
    "Karta byla přidána": "Karta bola pridaná",
    "Přidání karty se nezdařilo": "Pridanie karty sa nepodarilo",
    "Zadavatel": "Zadávateľ",
    "Startovací adresa": "Štartovacie adresa",
    "Cílová adresa": "Cieľová adresa",
    "Odjezd": "Odchod",
    "Rozcestník": "Rozcestník",
    "Moje zůstatky": "Moje zostatky",
    "E-shop": "E-shop",
    "Cafeteria": "Cafeteria",
    "Spolujízda": "Spolujazda",
    "Cestujete do zaměstnání vozem a chcete snížit náklady na dojíždění? Cestujete veřejnou dopravou a rádi byste se s někým svezli? Třeba kolega v jiné kanceláři právě hledá dojíždění nebo nabízí spolujízdu.\n\nZadejte zde svou nabídku na spolujízdu, nebo si prohlédněte již vystavené nabídky. Spolujízda zaručí, že budete vždy jednat s kolegou z Vaší firmy.":
        "Cestujete do zamestnania vozidlom a chcete znížiť náklady na dochádzanie? Cestujete verejnou dopravou a radi by ste sa s niekým zviezli? Trebárs kolega v inej kancelárii práve hľadá dochádzanie alebo ponúka spolujazdu.\n\nZadajte tu svoju ponuku na spolujazdu, alebo si prezrite už vystavenej ponuky. Spolujazda zaručí, že budete vždy jednať s kolegom z Vašej firmy.",
    "Přidat inzerát": "Pridať inzerát",
    "Používám aplikaci Benefit Plus a je to paráda! Vyzkoušej to taky a stahuj.":
        "Používam aplikáciu Benefit Plus a je to paráda! Vyskúšaj to tiež a sťahuj.",
    "Upravit inzerát": "Upraviť inzerát",
    "Místo": "Miesto",
    "Hledat místo": "Hľadať miesto",
    "Zpráva": "Správa",
    "Editovat": "Editovať",
    "Zadejte adresu": "Zadajte adresu",
    "Pouze vlastní inzeráty": "Iba vlastné inzeráty",
    "Přechází přes": "Prechádza cez",
    "Čas odjezdu": "Čas odchodu",
    "Poznámka": "Poznámka",
    "Uložit": "Uložiť",
    "Uložení spolujízdy se nezdařilo": "Uloženie spolujazdy zlyhalo",
    "Uložení spolujízdy proběhlo úspěšně": "Uloženie spolujazdy prebehlo úspešne",
    "Aktualizace spolujízdy se nezdařila": "Aktualizácia spolujazdy zlyhala",
    "Aktualizace spolujízdy proběhla úspěšně": "Aktualizácia spolujazdy prebehla úspešne",
    "Mám zájem o spolujízdu": "Mám záujem o spolujazdu",
    "Nová spolujízda": "Nová spolujazda",
    "Upravit spolujízdu": "Upraviť spolujazdu",
    "Detail spolujízdy": "Detail spolujazdy",
    "Napište adresu a potvrďte ji kliknutím na položku seznamu":
        "Napíšte adresu a potvrďte ju kliknutím na položku zoznamu",
    "Spolujízda z {1} do {2}": {phrase: "Spolujazda z {1} do {2}", 1: "", 2: ""},
    "Získat v": "Získať v",
    "Stažení PDF se nezdařilo": "Stiahnutie PDF zlyhalo",
};
