import {Action, ActionCreator} from "typescript-fsa";

import {ResourceState} from "../../types/ResourceState";
import {IAsyncDynamicReducer} from "../reducers/types/IAsyncDynamicReducer";
import {ISagaDynamicReducer} from "../reducers/types/ISagaDynamicReducer";
import {DynamicReducerType} from "../reducers/types/Types";
import {AsyncActionCreatorType, isAsyncAction, isSyncAction, ResponsePayloadType} from "./asyncActionCreator";
import {CreatedActionMap} from "./CreatedActionMap";

export class ActionExtractor {
    public static dispatch<S extends ResourceState, RQ, RS>(
        creator: {new (): DynamicReducerType<S, RQ, RS>},
        payload: RQ
    ): Action<RQ> {
        return this.getCreator(creator, "request")(payload) as Action<RQ>;
    }

    public static getCreator<S extends ResourceState, RQ, RS, T extends "request" | "response">(
        Creator: {new (): DynamicReducerType<S, RQ, RS>},
        type: T = "request" as T
    ): ActionCreator<typeof type extends "request" ? RQ : ResponsePayloadType<RS, RQ>> {
        const actionCreator: ActionCreator<RQ> | AsyncActionCreatorType<RQ, RS> = CreatedActionMap.get(
            Creator.prototype.type
        );
        if (!actionCreator || (!isAsyncAction(actionCreator) && !isSyncAction(actionCreator))) {
            throw new Error(`Action creator '${Creator.prototype.type}' does not exists.`);
        }
        return (isAsyncAction(actionCreator) ? actionCreator[type] : actionCreator) as ActionCreator<
            typeof type extends "request" ? RQ : ResponsePayloadType<RS, RQ>
        >;
    }

    public static getAsync<S extends ResourceState, RQ, RS>(Creator: {
        new (): ISagaDynamicReducer<RQ, RS, S> | IAsyncDynamicReducer<RQ, RS, S>;
    }): AsyncActionCreatorType<RQ, RS> {
        const actionCreator: ActionCreator<RQ> | AsyncActionCreatorType<RQ, RS> = CreatedActionMap.get(
            Creator.prototype.type
        );
        if (!isAsyncAction(actionCreator)) {
            throw new Error("Not an async action");
        }
        return actionCreator as AsyncActionCreatorType<RQ, RS>;
    }
}
