import * as React from "react";
import * as ReactDOM from "react-dom";
import {BasicProvider} from "../Common/BasicProvider";
import {DownloadNativeAppSuggestionBar} from "./DownloadNativeAppSuggestionBar";

const root = document.getElementById("download-native-app-suggestion-react-root");

ReactDOM.render(
    <BasicProvider root={root}>
        <DownloadNativeAppSuggestionBar/>
    </BasicProvider>,
    root
);
