export default {
    "Změny byly uloženy.": "Changes were saved.",
    "Více": "More",
    "Vítejte": "Welcome",
    "Nápověda": "Help",
    "Ochrana osobních údajů": "Protection of personal data",

    "Polohové služby": "Location Services",
    "Povolte nám přístup k Vaší poloze, abychom mohli zobrazovat benefity nejblíže k Vám.":
        "Please, enable access to your position so that we can show the benefits that are closest to you.",
    "Máte {1} nové zprávy.": {phrase: "You have {1} new messages.", 1: ""},
    "Uživatelské jméno": "User Name",
    "Heslo": "Password",
    "Přihlásit se": "Login",
    "Zapomněli jste heslo?": "Have you forgotten your password?",
    "Zaslat nové heslo": "Send New Password",
    "Zpět na přihlášení": "Back to Login",
    "Chcete si uložit {1} na Vaše zařízení?": {phrase: "Do you want to save {1} to your device?", 1: ""},
    "Stiskněte": "Press",
    "a poté": "and then",
    "(Přidat na plochu)": "(Add to Desktop)",
    "Kartou lze zaplatit": "Card Payment Available",
    "Karta Benefit Plus": "Benefit Plus Card",
    "Aktivovat kartu": "Activate Card",
    "Zablokovat kartu": "Lock Card",
    "Adresa": "Address",
    "Email": "Email",
    "Mapa": "Map",
    "Zobrazit mapu": "Show Map",
    "Telefon": "Phone",
    "Web": "Web",
    "Popis": "Description",
    "Kontakt": "Contact",
    "Objednat": "Order",
    "Minimální částka pro objednávku je {1}": {phrase: "The minimum price for the order is {1}", 1: ""},
    "Maximální částka pro objednávku je {1}": {phrase: "The maximum price for the order is {1}", 1: ""},
    "Objednat benefit": "Order Benefit",
    "Hodnota benefitu": "Value of Benefit",
    "Kč": "CZK",
    "Bodová hodnota": "Point Value",
    "Bodový koeficient": "Point Ratio",
    "1 Kč = 1,00 bodu": "1 CZK = 1.00 point",
    "bodů": "points",
    "Zůstatek peněženky": "Balance in the Wallet",
    "Poznámka k objednávce": "Note about the Order",
    "Tipy pro vás": "Hints for You",
    "Vyhledávání": "Searching",
    "Vaše poloha": "Your Location",
    "Chyba při zjištění polohy: [{1}] {2}": {phrase: "Error while finding position: [{1}] {2}", 1: "", 2: ""},
    "Rozpis platby": "Breakdown of Payment",
    "V mém okolí": "In my vicinity",
    "peněženka má": "the wallet has",
    "peněženky mají": "the wallets have",
    "Následující peněženky mají nedostatečný zůstatek pro provedení platby – {1}": {
        phrase: "There is insufficient balance in the following wallets to make the payment - {1}",
        1: "",
    },
    "Historie transakcí": "History of Transactions",
    "Celková hodnota benefitů k objednání:": "The total value of benefits to be ordered:",
    "Váš košík je zatím prázdný.": "Your basket is currently empty.",
    "Košík": "Basket",
    "Objednávka odeslána": "Order Submitted",
    "Děkujeme za objednávku, odeslali jsme ji na Váš email.":
        "Thank you for your order, we have sent it to your email.",
    "Celkem ({1} položek)": {phrase: "Total ({1} items)", 1: ""},
    "Kontakt na Benefit Plus": "Benefit Plus Contact",
    "Odhlásit se": "Logout",
    "Pro změnu nastavení přejít do webové aplikace": "Go to the web app to change the settings",
    "Zákaznické centrum Benefit Plus": "Benefit Plus Customer Centre",
    "Potřebujete pomoci? Jsme tu pro Vás v pracovní dny od {1} do {2}.": {
        phrase: "Need help? We are here for you on workdays from {1} to {2}.",
        1: "",
        2: "",
    },
    "Expirace:": "Expiration Date:",
    "Zpět": "Back",
    "Zrušit": "Cancel",
    "Smazat": "Clear",
    "Zavřít": "Close",
    "Info": "Info",
    "Menu": "Menu",
    "({1} hodnocení)": {phrase: "({1} ratings)", 1: ""},
    "Pohled z ulice": "View from the Street",
    "Zůstatky": "Balances",
    "Neznámé uživatelské jméno nebo chybné heslo.": "Unknown user name or incorrect password.",
    "Odkaz pro vygenerování nového hesla byl odeslán na Váš email.":
        "The link to generate a new password has been sent to your email.",
    "Zadané uživatelské jméno neexistuje": "The entered user name does not exist.",
    "Nastala chyba při resetování hesla": "There was an error resetting your password.",
    "Přidáno do košíku.": "Added to basket.",
    "Chyba při zpracování některé položky objednávky, zkuste odeslat košík prosím znovu.":
        "There was an error while processing some items of the order, please, submit the basket again.",
    "Chyba při odesílání objednávky, zkuste to prosím znovu.":
        "There was an error while submitting your order, please, try again.",
    "Položka košíku {1} ({2}) byla zpracována": {
        phrase: "Item in the basket {1} ({2}) has been processed.",
        1: "",
        2: "",
    },
    "Položka košíku byla zpracována": "Item in the basket has been processed.",
    "Potvrdit objednávku": "Confirm Order",
    "Vyberte možnost": "Select an option",
    "Vyplňte prosím všechna pole označená hvězdičkou.": "Please fill in all fields marked with an asterisk.",
    "roční": "yearly",
    "denní": "daily",
    "Objednání benefitu {1} v bodové hodnotě {2} by překročilo Váš {3} limit pro kategorii {4} o {5} bodů.": {
        phrase:
            "Purchasing benefit {1} for {2} points would exceed your {3} limit for the category {4} by {5} points.",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
    },
    "Objednání benefitu {1} v bodové hodnotě {2} by překročilo Váš {3} limit pro subkategorii {4} o {5} bodů.": {
        phrase:
            "Purchasing benefit {1} for {2} points would exceed your {3} limit for the subcategory {4} by {5} points.",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
    },
    "Objednání tohoto benefitu v bodové hodnotě {1} by překročilo Váš {2} limit pro kategorii {3} o {4} bodů.": {
        phrase:
            "Purchasing this benefit for {1} points would exceed your {2} limit for the category {3} by {4} points.",
        1: "",
        2: "",
        3: "",
        4: "",
    },
    "Objednání tohoto benefitu v bodové hodnotě {1} by překročilo Váš {2} limit pro subkategorii {3} o {4} bodů.": {
        phrase:
            "Purchasing this benefit for {1} points would exceed your {2} limit for the subcategory {3} by {4} points.",
        1: "",
        2: "",
        3: "",
        4: "",
    },
    "Přiložit soubor": "Attach a file",
    "Soubor je moc veliký ({1} MB). Maximální velikost souboru je {2} MB": {
        phrase: "The file is too big ({1} MB). The maximum file size is {2} MB",
        1: "",
        2: "",
    },
    "Nepodporovaný typ souboru ({1}). Podporované typy jsou {2}.": {
        phrase: "Unsupported file type ({1}). Supported types are {2}.",
        1: "",
        2: "",
    },
    "Zapamatovat přihlášení": "Remember login",
    "Vyhledat mezi transakcemi": "Search in transactions",
    "Smluvní partneři": "Contractual partners",
    "Pro přihlášení jako Smluvní partner prosím stahujte k tomu určenou aplikaci Benefit Plus Partner.":
        "To sign in as a Partner, please download the Benefit Plus Partner application.",

    "Zaslat jako dárkový poukaz": "Send as gift",
    "Jméno a příjmení obdarovaného": "Name and surname of gifted",
    "Moje oblíbené": "My Favorites",
    "Všechny podskupiny": "All Subgroups",
    "Zobrazit na mapě": "Show on map",

    "Seřadit dle adresy např. Vinohrady, Praha 2": "Sort by address eg. Prague 2",
    "Ano": "Yes",
    "Ne": "No",
    "Recenze": "Reviews",
    "dle {x} hodnocení": {
        phrase: "according to {x} ratings",
        plurals: {
            "sg": "according to {x} rating",
            "pl": "according to {x} ratings",
        },
        x: 0,
    },
    "Dokumenty ke stažení": "Files to download",

    "Můj profil": "My profile",
    "Karta benefit plus": "Benefit plus card",
    "Soukromá bankovní karta": "Private bank card",
    "Změna hesla": "Change password",
    "Notifikace událostí": "Event notifications",
    "Mohlo by vás také zajímat": "You may be interested in",
    "Jméno": "Name",
    "Příjmení": "Surname",
    "E-mail": "E-mail",
    "Jazyk aplikace": "App language",
    "Český": "Czech",
    "Slovenský": "Slovak",
    "Anglický": "English",
    "Uložit údaje": "Save data",
    "Změnit heslo": "Change password",
    "Stávající heslo": "Current password",
    "Nové heslo": "New password",
    "Potvrzení nového hesla": "Confirm new password",
    "Objednávka benefitu": "Benefit Order",
    "Doplatit bankovní kartou": "Pay deficiency with Credit Card",
    "Detailní podmínky": "Detailed Terms",
    "Souhlasím": "Agree",
    "Odečteme Vám": {phrase: "You will be charged: {1} {2}", 1: "", 2: ""},
    "Zaslat notifikace k následujícím modulům:": "Send notifications within following modules:",
    "Inovace": "Innovations",
    "Pochvaly": "Compliments",
    "Bazar": "Bazaar",
    "Spolky": "Groups",
    "Zasílat newsletter BP (1x měsíčně)": "Send newsletter BP (monthly)",
    "Nesouhlasím": "Disagree",

    "Uplatnit práva pro ochranu osobních údajů": "Apply policy rights",
    "Námitka proti zpracování z důvodu oprávněných zájmů správce osobních údajů":
        "Objection against processing due to legitimate interests of interest data manager",
    "Uplatnění práva na výmaz osobních údajů": "Apply right to delete personal data",
    "Uplatnění práva na kopii osobních údajů": "Apply right to copy personal data",
    "Uplatnění práva na přenos osobních údajů": "Apply right to transfer personal data",
    "Uplatnění práva na opravu osobních údajů": "Apply right to modify personal data",
    "Uplatnění práva na přístup k osobním údajům": "Apply right to access personal data",
    "Uplatnění práva nebýt předmětem žádného rozhodnutí založeného výhradně na automatizovaném robotovi":
        "Apply rights to not to be subject of any decision based on exclusively on automated bot",

    "Číslo karty": "Card number",
    "Aktivace karty": "Card activation",
    "Kartou lze platit": "Can pay by card",
    "Mobil pro kartu": "Phone number for card",
    "Email pro kartu": "Email for card",
    "Přidat druhý mobil pro kartu": "Add secondary phone number for card",
    "Přidat druhý email pro kartu": "Add secondary email for card",
    "Odblokovat kartu": "Unblock card",
    "Objednat náhradní kartu": "Order new card",
    "Načíst další": "Load more",
    "Datum napárování k BP": "Date of pairing to BP",
    "Expirace karty": "Card expiration",
    "Odebrat bankovní kartu": "Remove bank card",
    "Přidat bankovní kartu": "Add bank card",
    "Přečetl(a) jsem si ": "I have read ",
    "Podmínky využití soukromé bankovní karty ": "Terms and conditions of personal bank card usage ",
    "a souhlasím s nimi.": "and I agree to them.",
    "Objednat a doplatit {1}": {phrase: "Order and pay {1}", 1: ""},
    "Děkujeme za objednávku, poukázku jsme vám odeslali na e-mail. Nyní můžete přejít do historie transakcí a poukázku si vytisknout odeslat pomocí SMS na mobil.":
        'Thank you for your order, we have sent the voucher to your e-mail. You can now go to History of Transactions and <a href="#print" data-no-href="true">print</a> the voucher by sending it via <a href="#SMS" data-no-href="true">SMS</a> to your mobile.',
    "Zpět na košík": "Back to Basket",
    "Zobrazit": "Show",
    "Od": "From",
    "Do": "To",
    "Typ transakcí": "Transaction type",
    "Podmínky pro spárování soukromé bankovní karty s kartou Benefit Plus":
        "Conditions for Pairing a Private Bank Card with a Benefit Plus Card",
    "Podmínky jednorázového doplatku soukromou bankovní kartou": "Private Bank Card Single Payment Charge",
    "Rozpis platby: (hodnota objednávky bude odečtena z těchto účtů)":
        "Breakdown of Payment: (the value of this order will be deducted from the following accounts)",
    "Přihlásit pomocí biometriky": "Sign in with Biometrics",
    "Rozpoznání biometriky se nezdařilo": "Biometric detection failed",
    "Přihlášení se nezdařilo, nejprve povolte přihlášení biometrikou v nastavení profilu":
        "Login failed, first enable Biometrics login in profile settings",
    "Použít biometriku": "Use Biometrics",
    "Zadejte heslo": "Enter password",
    "Potvrďte heslo": "Confirm password",
    "Neplatné heslo": "Invalid password",
    "Zadaná hesla se neshodují": "The passwords you entered do not match",
    "Pro ověření potvrďte heslo": "Confirm password to verify",
    "Pro použití biometriky, si prosím nejdříve nastavte heslo":
        "To use Biometrics, please set a password first",
    "Nastavení": "Settings",
    "Povolit Touch ID": "Enable Touch ID",
    "Povolit Face ID": "Enable Face ID",
    "Čtečka otisku prstů": "Fingerprint Reader",
    "Přidáno do {1}": {phrase: "Added to {1}", 1: ""},
    "oblíbených": "favourites",
    "Odebráno z {1}": {phrase: "Removed from {1}", 1: ""},
    "Nástěnka": "Home",
    "Transakce": "Transactions",
    "Hledání": "Search",
    "Karta": "Card",
    "Nenalezeny žádné výsledky": "No results found.",
    "Nenalezena žádná karta": "No card found",

    "Kategorie": "Categories",
    "Filtrovat dle": "Filter by",
    "Kartou BP lze zaplatit": "Payment with Benefit Plus card allowed",
    "Kartou BP nelze zaplatit": "Payment with Benefit Plus card not allowed",
    "Přidat do oblíbených": "Add to favourites",
    "Odebrat z oblíbených": "Remove from favourites",
    "Hledat např. Wellness, Restaurace...": "Search for example Wellness, Restaurants...",
    "Seznam benefitů": "List of benefits",
    "Zobrazení": "Layout",
    "Potvrdit": "Confirm",
    "Všechny": "All",
    "Informace": "Information",
    "Kontakty": "Contacts",
    "Odhlásit": "Log out",
    "Přidat do {1}": {phrase: "Add do {1}", 1: ""},
    "Odebrat z {1}": {phrase: "Remove from {1}", 1: ""},
    "Oblíbené": "Favorite",
    "SMS": "SMS",
    "Hodnocení": "Rating",
    "Navigace": "Navigation",
    "Podskupiny": "Subgroups",
    "Další filtry": "Next Filters",
    "Seřadit dle vzdálenosti od": "Sort by distance from",
    "Informace o obchodním místě": "Point of Sale Information",
    "košíku": "Basket",
    "Vítejte v Cafeterii": "Welcome to Cafeteria",
    'Aplikace Benefit Plus má několik různých modulů. Cafeterie slouží k nákupu daňově zvýhodněných služeb, za které platíte benefitovými body. Pokud jste zde poprvé, podívejte se jak provést objednávku na <a href="{1}" target="_blank">úvodním videu</a>, nebo v <a href="{2}"> Nápovědě</a>.': {
        phrase:
            'Benefit Plus has several different modules. Cafeteria is used to purchase tax relief services for which you pay benefit points. If this is your first time here, see how to place an order on <a href="{1}" target="_blank">intro video</a> or <a href="{2}"> Help</a>.',
        1: "",
        2: "",
    },
    "Příště nezobrazovat": "Don't show again",
    "Odeslat pomocí SMS na telefon": "Send via SMS to phone",
    "Stáhnout poukaz": "Download voucher",
    "SMS zpráva byla odeslána": "SMS has been sent",
    "E-Stravenky": "E-Vouchers",
    "Text hodnocení": "Rating text",
    "Transakce byla ohodnocena.": "Transaction rated",
    "Seznam transakcí": "List of transactions",
    "Ohodnotit benefit": "Rate benefit",
    "Pokračovat v nákupu": "Continue shopping",
    "Stáhnout jako Excel tabulku": "Download as Excel",
    "Název benefitu": "Benefit Name",
    "Poskytovatel": "Provider",
    "Město": "City",
    "Karta BP": "BP Card",
    "Přidat do košíku": "Add to shopping cart",
    "Zvolte položku": "Choose item",
    "Přidat soubor": "Attach file",
    "Nové benefity": "New benefits",
    "Platba kartou BP": "BP card payment",
    "Odečteme Vám {1}": {phrase: "We will charge {1}", 1: ""},
    "Aktivace karty se nezdařila": "Card activation failed",
    "Aktualizace karty se nezdařila": "Card data update failed",
    "Blokace karty se nezdařila": "Card lock failed",
    "Deaktivace karty se nezdařila": "Card deactivation failed",
    "Objednávka karty se nezdařila": "Card order failed",
    "Odblokování karty se nezdařilo": "Card unblock failed",
    "Nastavení notifikací se neuložilo": "Notification settings have not been saved",
    "Nastavení notifikací proběhlo v pořádku": "Notification settings have been saved",
    "Změna hesla se nezdařila": "Password change failed",
    "Přidání avataru se nezdařilo": "Avatar upload failed",
    "Vymazání avataru se nezdařilo": "Avatar removal failed",
    "Vymazání avataru bylo úspěšné": "Avatar removal successful",
    "Nahrát": "Upload",
    "Profil": "Profile",
    "Hlavní nastavení": "Main settings",
    "Ostatní nastavení": "Other settings",
    "Sdílet": "Share",
    "Hodnotit": "Rate App",
    "Verze": "Version",
    "Nastavení jazyka": "Language settings",
    "Změna jazyka se nepodařila": "Language change failed",
    "Jazyk aplikace byl úspěšně změněn": "Application language changed successfully",
    "Vyhledávání dle fráze": "Fulltext search",
    "Zde můžete prohledávat seznam benefitů dle Vámi vybraných kriterii.":
        "Here You can search benefits according to chosen criteria.",
    "Nastala chyba při přihlášení": "Login Error",
    "Aktualizace profilu se nezdařila": "Profile update failed",
    "Aktualizace profilu proběhla úspěšně": "Profile update was successful",
    "Karta byla aktualizována": "Card was updated",
    "Karta byla odblokována": "Card was unblocked",
    "Karta byla zablokována": "Card was blocked",
    "Karta byla aktivována": "Card was activated",
    "Heslo bylo změněno": "Password was changed",
    "Skupiny a podskupiny": "Groups and subgroups",
    "Karta byla odebrána": "Card was removed",
    "Odebrání karty se nezdařilo": "Card removal failed",
    "Karta byla přidána": "Card was added",
    "Přidání karty se nezdařilo": "There was an error while adding a card",
    "Zadavatel": "Author", // put bettter translation
    "Startovací adresa": "Start address",
    "Cílová adresa": "Destination address",
    "Odjezd": "Departure",
    "Rozcestník": "Home Page",
    "Moje zůstatky": "My balances",
    "E-shop": "E-shop",
    "Cafeteria": "Cafeteria",
    "Spolujízda": "Carpooling",
    "Cestujete do zaměstnání vozem a chcete snížit náklady na dojíždění? Cestujete veřejnou dopravou a rádi byste se s někým svezli? Třeba kolega v jiné kanceláři právě hledá dojíždění nebo nabízí spolujízdu.\n\nZadejte zde svou nabídku na spolujízdu, nebo si prohlédněte již vystavené nabídky. Spolujízda zaručí, že budete vždy jednat s kolegou z Vaší firmy.":
        "Do you travel to work by car and want to reduce the cost of commuting? Do you travel by public transport and would you like to ride with someone? For example, a colleague in another office is looking for a commute or is offering a ride.\n\nEnter your ride offer here, or view offers that have already been posted. Carpooling guarantees that you will always deal with a colleague from your company.",
    "Používám aplikaci Benefit Plus a je to paráda! Vyzkoušej to taky a stahuj.":
        "I'm using the Benefit Plus app and it's great! Try it too and download.",
    "Upravit inzerát": "Edit ad",
    "Přidat inzerát": "Add ad",
    "Místo": "Place",
    "Hledat místo": "Search for a place",
    "Zpráva": "Message",
    "Editovat": "Edit",
    "Zadejte adresu": "Enter the address",
    "Pouze vlastní inzeráty": "Own ads only",
    "Přechází přes": "Crossing",
    "Čas odjezdu": "Departure time",
    "Poznámka": "Note",
    "Uložit": "Save",
    "Uložení spolujízdy se nezdařilo": "Failed to save carpool",
    "Uložení spolujízdy proběhlo úspěšně": "Saving carpool was successful",
    "Aktualizace spolujízdy se nezdařila": "Carpool update failed",
    "Aktualizace spolujízdy proběhla úspěšně": "Carpool update was successful",
    "Mám zájem o spolujízdu": "I'm interested in a ride",
    "Nová spolujízda": "New carpool",
    "Upravit spolujízdu": "Edit carpool",
    "Detail spolujízdy": "Carpool detail",
    "Napište adresu a potvrďte ji kliknutím na položku seznamu":
        "Enter the address and confirm it by clicking on the list item",
    "Spolujízda z {1} do {2}": {phrase: "Carpooling from {1} to {2}", 1: "", 2: ""},
    "Získat v": "Download in",
    "Stažení PDF se nezdařilo": "PDF download failed",
};
