import * as React from "react";

import {AppLang} from "@bp/core-dependency/src/localization/AppLang";
import {Language} from "@bp/core-dependency/src/localization/Language";

import {AssetSvg} from "../types/AssetSvg";
import {LocalizedAssetSvg} from "../types/LocalizedAssetSvg";

type ComponentType = React.ComponentType<React.SVGProps<SVGSVGElement>>;
export const createLocalizedAsset = (
    input: Record<Language, [string, ComponentType]>
): LocalizedAssetSvg => ({
    current(): AssetSvg {
        return {
            src: this.sources[AppLang.language],
            Component: this.components[AppLang.language],
        };
    },
    sources: Object.assign({}, ...Object.keys(input).map((lang) => ({[lang]: input[lang][0]}))),
    components: Object.assign({}, ...Object.keys(input).map((lang) => ({[lang]: input[lang][1]}))),
});
