import {Action} from "typescript-fsa";

import {ResourceState} from "../../../types/ResourceState";
import {IAsyncDynamicReducer} from "./IAsyncDynamicReducer";
import {IDynamicReducer} from "./IDynamicReducer";
import {ISagaDynamicReducer} from "./ISagaDynamicReducer";

export type ActionHandler<S extends ResourceState, T> = (
    action: Action<T>,
    newState: S,
    oldState: S,
    initialState: () => S
) => S;

export const isDynamicReducer = <S extends ResourceState, RQ, RS = any>(
    reducer: IAsyncDynamicReducer<RQ, RS, S> | IDynamicReducer<RQ, S>
): reducer is IDynamicReducer<RQ, S> => "handle" in reducer;

export const isAsyncDynamicReducer = <S extends ResourceState, RQ, RS = any>(
    reducer: IAsyncDynamicReducer<RQ, RS, S> | IDynamicReducer<RQ, S>
): reducer is IAsyncDynamicReducer<RQ, RS, S> => !isDynamicReducer(reducer);

export type DynamicReducerType<S extends ResourceState, RQ, RS = any> =
    | ISagaDynamicReducer<RQ, RS, S>
    | IAsyncDynamicReducer<RQ, RS, S>
    | IDynamicReducer<RQ, S>;
