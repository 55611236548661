import * as React from "react";
import classNames from "classnames";
import {Button, createStyles, IconButton, Theme, Typography, withStyles} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {mobileVendor} from "react-device-detect";
import {connect} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Dispatch} from "redux";

import {L} from "@bp/benefit-plus-core/src/i18n/L";
import {WithStyles} from "@bp/core-dependency/src/types/withStyles";
import {ActionExtractor} from "@bp/core-react/src/redux/actions/ActionExtractor";

import Routes from "../../../../Routes";
import {Assets} from "../../../../Assets";
import {HideDownloadNativeAppSuggestion} from "../../../../redux/process/HideDownloadNativeAppSuggestion";
import {GlobalState} from "../../../../types/GlobalState";

interface Props extends WithStyles<typeof styles> {
    hideDownloadNativeAppSuggestion: boolean;
    onDownloadClick: () => void;
    onDismiss: () => void;
}

interface State {}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.common.white,
            alignItems: "center",
        },
        rowFlex: {
            display: "flex",
            flexDirection: "row",
            borderRadius: 0,
            width: "100%",
            justifyContent: "flex-start",
            textTransform: "none",
        },
        button: {
            alignItems: "stretch",
        },
        image: {
            width: theme.spacing(8),
            height: theme.spacing(8),
            marginRight: theme.spacing(1),
            flexGrow: 0,
            overflow: "hidden",
            borderRadius: theme.shape.borderRadius,
        },
        text: {
            flexGrow: 1,
            textAlign: "left",
            color: theme.palette.grey[600],
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "space-between",
        },
        downloadText: {
            textAlign: "right",
            color: theme.palette.primary.main,
            textTransform: "uppercase",
        },
        viewInStore: {
            fontSize: theme.typography.pxToRem(12),
            color: theme.palette.grey[400],
        },
    });

@((withStyles as any)(styles))
export class DownloadNativeAppSuggestion extends React.Component<Props, State> {
    render() {
        if (this.props.hideDownloadNativeAppSuggestion) {
            return null;
        }
        return (
            <div className={classNames(this.props.classes!.root, this.props.classes!.rowFlex)}>
                <IconButton onClick={this.props.onDismiss} size="small">
                    <Close />
                </IconButton>
                <Button
                    classes={{root: classNames(this.props.classes!.button, this.props.classes!.rowFlex)}}
                    onClick={this.props.onDownloadClick}
                >
                    <div className={this.props.classes!.image}>
                        <img src={Assets.IMG_LOGO_SQUARE} width="100%" height="100%" />
                    </div>
                    <div className={this.props.classes!.text}>
                        <Typography>BenefitPlus</Typography>
                        <Typography classes={{root: this.props.classes!.downloadText}}>
                            {L.get("Zobrazit")}
                        </Typography>
                        <Typography classes={{root: this.props.classes!.viewInStore}}>
                            {L.get("Získat v")}{" "}
                            {mobileVendor.toLowerCase() == "huawei" ? "AppGallery" : "Play Store"}
                        </Typography>
                    </div>
                </Button>
            </div>
        );
    }
}

interface ReduxProps {}

interface DispatchProps {}

@(connect(
    (state: GlobalState): ReduxProps & Partial<Props> => ({
        hideDownloadNativeAppSuggestion: state.hideDownloadNativeAppSuggestion,
    }),
    (dispatch: Dispatch): DispatchProps & Partial<Props> => ({
        onDismiss: () => dispatch(ActionExtractor.dispatch(HideDownloadNativeAppSuggestion, {})),
    })
) as any)
export class DownloadNativeAppSuggestionRedux extends React.Component<
    Partial<Props & DispatchProps & ReduxProps>
> {
    private onDownloadClick = () => {
        window.location.href =
            mobileVendor.toLowerCase() == "huawei"
                ? "https://appgallery7.huawei.com/#/app/C101693229"
                : "https://play.google.com/store/apps/details?id=xx.mbp";
    };

    render() {
        return (
            <DownloadNativeAppSuggestion {...(this.props as any)} onDownloadClick={this.onDownloadClick} />
        );
    }
}
