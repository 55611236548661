import {Action} from "typescript-fsa";

import {coreStorage} from "@bp/benefit-plus-core/src/modules/CoreStorage";
import {ActionCreator} from "@bp/core-react/src/redux/actions/decorators/ActionCreator";
import {IDynamicReducer} from "@bp/core-react/src/redux/reducers/types/IDynamicReducer";

import {GlobalState} from "../../types/GlobalState";

export type Payload = {};

@ActionCreator()
export class HideDownloadNativeAppSuggestion implements IDynamicReducer<Payload, GlobalState> {
    handle(
        action: Action<Payload>,
        newState: GlobalState,
        oldState: GlobalState,
        initialState: () => GlobalState
    ): GlobalState {
        newState.hideDownloadNativeAppSuggestion = true;
        coreStorage.setItem("hideDownloadNativeAppSuggestion", true, {temporary: true});
        return newState;
    }
}
