import {Action} from "typescript-fsa";

import {ResourceState} from "../../../types/ResourceState";
import {IAsyncDynamicReducer} from "./IAsyncDynamicReducer";
import {IDynamicReducer} from "./IDynamicReducer";
import {isAsyncDynamicReducer} from "./Types";

export interface ISagaDynamicReducer<RQ, RS, S extends ResourceState>
    extends IAsyncDynamicReducer<RQ, RS, S> {
    watch(action: Action<RQ>, state: S): Generator<any, any, any>;
}

export const isSagaDynamicReducer = <S extends ResourceState, RQ, RS = any>(
    reducer: IAsyncDynamicReducer<RQ, RS, S> | IDynamicReducer<RQ, S>
): reducer is ISagaDynamicReducer<RQ, RS, S> => isAsyncDynamicReducer(reducer) && "watch" in reducer;
