import {ResourceState} from "../../types/ResourceState";
import {AsyncActionCreatorType} from "../actions/asyncActionCreator";
import {ISagaDynamicReducer} from "../reducers/types/ISagaDynamicReducer";
import {Take} from "./helpers";

export type SagaReducerPair = {
    action: AsyncActionCreatorType<any, any>;
    reducer: ISagaDynamicReducer<any, any, any>;
    take: Take;
};

export class SagaReducerMap {
    private static _sagas: SagaReducerPair[] = [];

    public static addSagaReducer<RQ, RS, S extends ResourceState>(
        action: AsyncActionCreatorType<RQ, RS>,
        reducer: ISagaDynamicReducer<RQ, RS, S>,
        take: Take
    ) {
        SagaReducerMap._sagas.push({action, reducer, take});
    }

    public static get sagas(): SagaReducerPair[] {
        return SagaReducerMap._sagas;
    }
}
