import {put, select, takeEvery, takeLatest} from "redux-saga/effects";
import {Action, isType} from "typescript-fsa";

import {APIResponse} from "@bp/core-dependency/src/api/APIResponse";

import {AsyncActionCreatorType} from "../actions/asyncActionCreator";

export enum Take {
    Every,
    Latest,
}

// any due to https://github.com/Microsoft/TypeScript/issues/2983#issuecomment-230404301
export function watch<RequestPayloadType, ResponsePayloadType, S extends object>(
    asyncAction: AsyncActionCreatorType<RequestPayloadType, ResponsePayloadType>,
    call: (action: Action<RequestPayloadType>, state: S) => Generator<any, any, any>,
    take: Take = Take.Latest
) {
    return (function*() {
        const f = function*(action: any) {
            if (isType(action, asyncAction.request)) {
                const state: S = yield select((state: S) => state);
                try {
                    const response = (action as any)._cachedResponse
                        ? (action as any)._cachedResponse
                        : yield call(action, state);
                    yield put(
                        asyncAction.response({
                            response,
                            requestPayload: {...(action.payload as any)} as any,
                        } as any)
                    );
                } catch (e) {
                    console.error("Error with action", action.type, e);
                    yield put(
                        asyncAction.response({
                            response: new APIResponse(500, null, undefined),
                            requestPayload: {...(action.payload as any)} as any,
                        } as any)
                    );
                }
            }
        };
        if (take == Take.Every) {
            yield takeEvery(asyncAction.request.type, f);
        } else if (take == Take.Latest) {
            yield takeLatest(asyncAction.request.type, f);
        }
    })();
}
