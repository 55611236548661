import * as _ from "lodash";
import {ActionCreator} from "typescript-fsa";

import {ResourceState} from "../../types/ResourceState";
import {AsyncActionCreatorType, isAsyncAction, isSyncAction} from "../actions/asyncActionCreator";
import {ActionHandler, DynamicReducerType, isAsyncDynamicReducer, isDynamicReducer} from "./types/Types";

export interface ReducerActionPair<S extends ResourceState, T> {
    handler?: ActionHandler<S, T>;
    beforeHandler?: ActionHandler<S, T>;
    action: ActionCreator<T>;
}

export class ReducerActionMap {
    public static _reducers: {[K: string]: ReducerActionPair<any, any>[]} = {};

    public static getReducers<S extends ResourceState = any>(key: string): ReducerActionPair<S, any>[] {
        return this._reducers[key] || [];
    }

    public static get reducers(): ReducerActionPair<any, any>[] {
        return _.flatMap(ReducerActionMap._reducers);
    }

    public static addReducer<S extends ResourceState, RQ, RS = undefined>(
        action: ActionCreator<RQ> | AsyncActionCreatorType<RQ, RS>,
        reducer: DynamicReducerType<S, RQ, RS>
    ) {
        if (isAsyncAction<RQ, RS>(action) && isAsyncDynamicReducer(reducer)) {
            ReducerActionMap.addReducerToMap<S, RQ>(
                action.request,
                reducer.handleRequest?.bind(reducer),
                reducer.beforeHandleRequest?.bind(reducer)
            );
            ReducerActionMap.addReducerToMap<S, RS>(
                action.response,
                reducer.handleResponse?.bind(reducer),
                reducer.beforeHandleResponse?.bind(reducer)
            );
        } else if (isDynamicReducer(reducer) && isSyncAction<RQ>(action)) {
            ReducerActionMap.addReducerToMap<S, RQ>(
                action,
                reducer.handle.bind(reducer) as ActionHandler<S, RQ>
            );
        }
    }

    private static addReducerToMap<S extends ResourceState, T>(
        action: ActionCreator<T>,
        handler?: ActionHandler<S, T>,
        beforeHandler?: ActionHandler<S, T>
    ) {
        if (handler || beforeHandler) {
            if (!this._reducers[action.type]) {
                this._reducers[action.type] = [];
            }
            this._reducers[action.type].push({action, handler, beforeHandler});
        }
    }
}
