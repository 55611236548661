import * as React from "react";
import {createStyles, Theme, withStyles} from "@material-ui/core";
import {WithStyles} from "@bp/core-dependency/src/types/withStyles";
import {isIOS, isMobile, mobileVendor} from "react-device-detect";
import {DownloadNativeAppSuggestion} from "@bp/benefit-plus-mobile/src/components/tsx/display/DownloadNativeApp/DownloadNativeAppSuggestion";
import {MetaIosTag} from "./MetaIosTag";

interface Props extends WithStyles<typeof styles> {
}

interface State {
    hideDownloadNativeAppSuggestion: boolean;
}

const styles = (theme: Theme) => createStyles({});

@(withStyles as any)(styles)
export class DownloadNativeAppSuggestionBar extends React.Component<Props, State> {
    private static readonly STORAGE_KEY = "hideDownloadNativeAppSuggestion";

    state = {
        hideDownloadNativeAppSuggestion: sessionStorage.getItem(DownloadNativeAppSuggestionBar.STORAGE_KEY) === JSON.stringify(true),
    };

    private onDownloadClick = () => {
        window.location.href =
            mobileVendor.toLowerCase() == "huawei"
                ? "https://appgallery7.huawei.com/#/app/C101693229"
                : "https://play.google.com/store/apps/details?id=xx.mbp";
        this.onDismiss();
    };
    private onDismiss = () => {
        sessionStorage.setItem(DownloadNativeAppSuggestionBar.STORAGE_KEY, JSON.stringify(true));
        this.setState({hideDownloadNativeAppSuggestion: true});
    };

    render() {
        if (!isMobile) {
            return null;
        }
        if (isIOS) {
            return <MetaIosTag/>;
        }
        return (
            <DownloadNativeAppSuggestion hideDownloadNativeAppSuggestion={this.state.hideDownloadNativeAppSuggestion}
                                         onDownloadClick={this.onDownloadClick}
                                         onDismiss={this.onDismiss}/>
        );
    }
}
