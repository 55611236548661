import {ActionCreator} from "typescript-fsa";

import {AsyncActionCreatorType} from "./asyncActionCreator";

export class CreatedActionMap {
    private static _map: {[K: string]: ActionCreator<any> | AsyncActionCreatorType<any, any>} = {};

    public static put(name: string, action: ActionCreator<any> | AsyncActionCreatorType<any, any>) {
        this._map[name] = action;
    }

    public static get<RQ, RS = any>(name: string): ActionCreator<RQ> | AsyncActionCreatorType<RQ, RS> {
        return this._map[name] as ActionCreator<RQ> | AsyncActionCreatorType<RQ, RS>;
    }
}
