import {ActionCreator, actionCreatorFactory} from "typescript-fsa";

import {APIResponse} from "@bp/core-dependency/src/api/APIResponse";

const actionCreator = actionCreatorFactory();

export interface ResponsePayloadType<ResponseType, RequestPayloadType> {
    response: APIResponse<ResponseType>;
    requestPayload: RequestPayloadType;
}

export interface AsyncActionCreatorType<RequestPayloadType, ResponsePayloadType> {
    request: ActionCreator<RequestPayloadType>;
    response: ActionCreator<ResponsePayloadType>;
}

export const isAsyncAction = <RQ, RS>(
    action: AsyncActionCreatorType<RQ, RS> | any
): action is AsyncActionCreatorType<RQ, RS> => "request" in action && "response" in action;

export const isSyncAction = <T>(action: any | ActionCreator<T>): action is ActionCreator<T> =>
    "type" in action;

export const asyncActionCreator = <ResponseType, RequestPayloadType>(
    type: string
): AsyncActionCreatorType<RequestPayloadType, ResponsePayloadType<ResponseType, RequestPayloadType>> => {
    return {
        request: actionCreator<RequestPayloadType>(type),
        response: actionCreator<ResponsePayloadType<ResponseType, RequestPayloadType>>(type + "_response"),
    };
};
