export default {
    "Změny byly uloženy.": "Změny byly uloženy.",
    "Více": "Více",
    "Vítejte": "Vítejte",
    "Nápověda": "Nápověda",
    "Ochrana osobních údajů": "Ochrana osobních údajů",

    "Polohové služby": "Polohové služby",
    "Povolte nám přístup k Vaší poloze, abychom mohli zobrazovat benefity nejblíže k Vám.":
        "Povolte nám přístup k Vaší poloze, abychom mohli zobrazovat benefity nejblíže k Vám.",
    "Máte {1} nové zprávy.": {phrase: "Máte {1} nové zprávy.", 1: ""},
    "Uživatelské jméno": "Uživatelské jméno",
    "Heslo": "Heslo",
    "Přihlásit se": "Přihlásit se",
    "Zapomněli jste heslo?": "Zapomněli jste heslo?",
    "Zaslat nové heslo": "Zaslat nové heslo",
    "Zpět na přihlášení": "Zpět na přihlášení",
    "Chcete si uložit {1} na Vaše zařízení?": {phrase: "Chcete si uložit {1} na Vaše zařízení?", 1: ""},
    "Stiskněte": "Stiskněte",
    "a poté": "a poté",
    "(Přidat na plochu)": "(Přidat na plochu)",
    "Kartou lze zaplatit": "Kartou lze zaplatit",
    "Karta Benefit Plus": "Karta Benefit Plus",
    "Aktivovat kartu": "Aktivovat kartu",
    "Zablokovat kartu": "Zablokovat kartu",
    "Adresa": "Adresa",
    "Email": "Email",
    "Mapa": "Mapa",
    "Zobrazit mapu": "Zobrazit mapu",
    "Telefon": "Telefon",
    "Web": "Web",
    "Popis": "Popis",
    "Kontakt": "Kontakt",
    "Objednat": "Objednat",
    "Minimální částka pro objednávku je {1}": {phrase: "Minimální částka pro objednávku je {1}", 1: ""},
    "Maximální částka pro objednávku je {1}": {phrase: "Maximální částka pro objednávku je {1}", 1: ""},
    "Objednat benefit": "Objednat benefit",
    "Hodnota benefitu": "Hodnota benefitu",
    "Kč": "Kč",
    "Bodová hodnota": "Bodová hodnota",
    "Bodový koeficient": "Bodový koeficient",
    "1 Kč = 1,00 bodu": "1 Kč = 1,00 bodu",
    "bodů": "bodů",
    "Zůstatek peněženky": "Zůstatek peněženky",
    "Poznámka k objednávce": "Poznámka k objednávce",
    "Tipy pro vás": "Tipy pro Vás",
    "Vyhledávání": "Vyhledávání",
    "Vaše poloha": "Vaše poloha",
    "Chyba při zjištění polohy: [{1}] {2}": {phrase: "Chyba při zjištění polohy: [{1}] {2}", 1: "", 2: ""},
    "Rozpis platby": "Rozpis platby",
    "V mém okolí": "V mém okolí",
    "peněženka má": "peněženka má",
    "peněženky mají": "peněženky mají",
    "Následující peněženky mají nedostatečný zůstatek pro provedení platby – {1}": {
        phrase: "Následující peněženky mají nedostatečný zůstatek pro provedení platby – {1}",
        1: "",
    },
    "Historie transakcí": "Historie transakcí",
    "Celková hodnota benefitů k objednání:": "Celková hodnota benefitů k objednání:",
    "Váš košík je zatím prázdný.": "Váš košík je zatím prázdný.",
    "Košík": "Košík",
    "Objednávka odeslána": "Objednávka odeslána",
    "Děkujeme za objednávku, odeslali jsme ji na Váš email.":
        "Děkujeme za objednávku, odeslali jsme ji na Váš email.",
    "Celkem ({1} položek)": {phrase: "Celkem ({1} položek)", 1: ""},
    "Kontakt na Benefit Plus": "Kontakt na Benefit Plus",
    "Odhlásit se": "Odhlásit se",
    "Pro změnu nastavení přejít do webové aplikace": "Pro změnu nastavení přejít do webové aplikace",
    "Zákaznické centrum Benefit Plus": "Zákaznické centrum Benefit Plus",
    "Potřebujete pomoci? Jsme tu pro Vás v pracovní dny od {1} do {2}.": {
        phrase: "Potřebujete pomoci? Jsme tu pro Vás v pracovní dny od {1} do {2}.",
        1: "",
        2: "",
    },
    "Expirace:": "Expirace:",
    "Zpět": "Zpět",
    "Zrušit": "Zrušit",
    "Smazat": "Smazat",
    "Zavřít": "Zavřít",
    "Info": "Info",
    "Menu": "Menu",
    "({1} hodnocení)": {phrase: "({1} hodnocení)", 1: ""},
    "Pohled z ulice": "Pohled z ulice",
    "Zůstatky": "Zůstatky",
    "Neznámé uživatelské jméno nebo chybné heslo.": "Neznámé uživatelské jméno nebo chybné heslo.",
    "Odkaz pro vygenerování nového hesla byl odeslán na Váš email.":
        "Odkaz pro vygenerování nového hesla byl odeslán na Váš email.",
    "Zadané uživatelské jméno neexistuje": "Zadané uživatelské jméno neexistuje",
    "Nastala chyba při resetování hesla": "Nastala chyba při resetování hesla",
    "Přidáno do košíku.": "Přidáno do košíku.",
    "Chyba při zpracování některé položky objednávky, zkuste odeslat košík prosím znovu.":
        "Chyba při zpracování některé položky objednávky, zkuste odeslat košík prosím znovu.",
    "Chyba při odesílání objednávky, zkuste to prosím znovu.":
        "Chyba při odesílání objednávky, zkuste to prosím znovu.",
    "Položka košíku {1} ({2}) byla zpracována": {
        phrase: "Položka košíku {1} ({2}) byla zpracována",
        1: "",
        2: "",
    },
    "Položka košíku byla zpracována": "Položka košíku byla zpracována",
    "Potvrdit objednávku": "Potvrdit objednávku",
    "Vyberte možnost": "Vyberte možnost",
    "Vyplňte prosím všechna pole označená hvězdičkou.": "Vyplňte prosím všechna pole označená hvězdičkou.",
    "roční": "roční",
    "denní": "denní",
    "Objednání benefitu {1} v bodové hodnotě {2} by překročilo Váš {3} limit pro kategorii {4} o {5} bodů.": {
        phrase:
            "Objednání benefitu {1} v bodové hodnotě {2} by překročilo Váš {3} limit pro kategorii {4} o {5} bodů.",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
    },
    "Objednání benefitu {1} v bodové hodnotě {2} by překročilo Váš {3} limit pro subkategorii {4} o {5} bodů.": {
        phrase:
            "Objednání benefitu {1} v bodové hodnotě {2} by překročilo Váš {3} limit pro subkategorii {4} o {5} bodů.",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
    },
    "Objednání tohoto benefitu v bodové hodnotě {1} by překročilo Váš {2} limit pro kategorii {3} o {4} bodů.": {
        phrase:
            "Objednání tohoto benefitu v bodové hodnotě {1} by překročilo Váš {2} limit pro kategorii {3} o {4} bodů.",
        1: "",
        2: "",
        3: "",
        4: "",
    },
    "Objednání tohoto benefitu v bodové hodnotě {1} by překročilo Váš {2} limit pro subkategorii {3} o {4} bodů.": {
        phrase:
            "Objednání tohoto benefitu v bodové hodnotě {1} by překročilo Váš {2} limit pro subkategorii {3} o {4} bodů.",
        1: "",
        2: "",
        3: "",
        4: "",
    },
    "Přiložit soubor": "Přiložit soubor",
    "Soubor je moc veliký ({1} MB). Maximální velikost souboru je {2} MB": {
        phrase: "Soubor je moc veliký ({1} MB). Maximální velikost souboru je {2} MB",
        1: "",
        2: "",
    },
    "Nepodporovaný typ souboru ({1}). Podporované typy jsou {2}.": {
        phrase: "Nepodporovaný typ souboru ({1}). Podporované typy jsou {2}.",
        1: "",
        2: "",
    },
    "Zapamatovat přihlášení": "Zapamatovat přihlášení",
    "Vyhledat mezi transakcemi": "Vyhledat mezi transakcemi",
    "Smluvní partneři": "Smluvní partneři",
    "Pro přihlášení jako Smluvní partner prosím stahujte k tomu určenou aplikaci Benefit Plus Partner.":
        "Pro přihlášení jako Smluvní partner prosím stahujte k tomu určenou aplikaci Benefit Plus Partner.",

    "Zaslat jako dárkový poukaz": "Zaslat jako dárkový poukaz",
    "Jméno a příjmení obdarovaného": "Jméno a příjmení obdarovaného",
    "Moje oblíbené": "Moje oblíbené",
    "Všechny podskupiny": "Všechny podskupiny",
    "Zobrazit na mapě": "Zobrazit na mapě",

    "Seřadit dle adresy např. Vinohrady, Praha 2": "Seřadit dle adresy např. Vinohrady, Praha 2",
    "Ano": "Ano",
    "Ne": "Ne",
    "Recenze": "Recenze",
    "dle {x} hodnocení": {
        phrase: "dle {x} hodnocení",
        x: 0,
    },
    "Dokumenty ke stažení": "Dokumenty ke stažení",

    "Můj profil": "Můj profil",
    "Karta benefit plus": "Karta benefit plus",
    "Soukromá bankovní karta": "Soukromá bankovní karta",
    "Změna hesla": "Změna hesla",
    "Notifikace událostí": "Notifikace událostí",
    "Mohlo by vás také zajímat": "Mohlo by vás také zajímat",
    "Jméno": "Jméno",
    "Příjmení": "Příjmení",
    "E-mail": "E-mail",
    "Jazyk aplikace": "Jazyk aplikace",
    "Český": "Český",
    "Slovenský": "Slovenský",
    "Anglický": "Anglický",
    "Uložit údaje": "Uložit údaje",
    "Změnit heslo": "Změnit heslo",
    "Stávající heslo": "Stávající heslo",
    "Nové heslo": "Nové heslo",
    "Potvrzení nového hesla": "Potvrzení nového hesla",
    "Objednávka benefitu": "Objednávka benefitu",
    "Doplatit bankovní kartou": "Doplatit bankovní kartou",
    "Detailní podmínky": "Detailní podmínky",
    "Souhlasím": "Souhlasím",
    "Odečteme Vám": {phrase: "Odečteme Vám: {1} {2}", 1: "", 2: ""},
    "Zaslat notifikace k následujícím modulům:": "Zaslat notifikace k následujícím modulům:",
    "Inovace": "Inovace",
    "Pochvaly": "Pochvaly",
    "Bazar": "Bazar",
    "Spolky": "Spolky",
    "Zasílat newsletter BP (1x měsíčně)": "Zasílat newsletter BP (1x měsíčně)",
    "Nesouhlasím": "Nesouhlasím",

    "Uplatnit práva pro ochranu osobních údajů": "Uplatnit práva pro ochranu osobních údajů",
    "Námitka proti zpracování z důvodu oprávněných zájmů správce osobních údajů":
        "Námitka proti zpracování z důvodu oprávněných zájmů správce osobních údajů",
    "Uplatnění práva na výmaz osobních údajů": "Uplatnění práva na výmaz osobních údajů",
    "Uplatnění práva na kopii osobních údajů": "Uplatnění práva na kopii osobních údajů",
    "Uplatnění práva na přenos osobních údajů": "Uplatnění práva na přenos osobních údajů",
    "Uplatnění práva na opravu osobních údajů": "Uplatnění práva na opravu osobních údajů",
    "Uplatnění práva na přístup k osobním údajům": "Uplatnění práva na přístup k osobním údajům",
    "Uplatnění práva nebýt předmětem žádného rozhodnutí založeného výhradně na automatizovaném robotovi":
        "Uplatnění práva nebýt předmětem žádného rozhodnutí založeného výhradně na automatizovaném robotovi",

    "Číslo karty": "Číslo karty",
    "Aktivace karty": "Aktivace karty",
    "Kartou lze platit": "Kartou lze platit",
    "Mobil pro kartu": "Mobil pro kartu",
    "Email pro kartu": "Email pro kartu",
    "Přidat druhý mobil pro kartu": "Přidat druhý mobil pro kartu",
    "Přidat druhý email pro kartu": "Přidat druhý email pro kartu",
    "Odblokovat kartu": "Odblokovat kartu",
    "Objednat náhradní kartu": "Objednat náhradní kartu",
    "Načíst další": "Načíst další",
    "Datum napárování k BP": "Datum napárování k BP",
    "Expirace karty": "Expirace karty",
    "Odebrat bankovní kartu": "Odebrat bankovní kartu",
    "Přidat bankovní kartu": "Přidat bankovní kartu",
    "Přečetl(a) jsem si ": "Přečetl(a) jsem si ",
    "Podmínky využití soukromé bankovní karty ": "Podmínky využití soukromé bankovní karty ",
    "a souhlasím s nimi.": "a souhlasím s nimi.",
    "Objednat a doplatit {1}": {phrase: "Objednat a doplatit {1}", 1: ""},
    "Děkujeme za objednávku, poukázku jsme vám odeslali na e-mail. Nyní můžete přejít do historie transakcí a poukázku si vytisknout odeslat pomocí SMS na mobil.":
        'Děkujeme za objednávku, poukázku jsme vám odeslali na e-mail. Nyní můžete přejít do historie transakcí a poukázku si <a href="#print" data-no-href="true">vytisknout</a> odeslat pomocí <a href="#SMS" data-no-href="true">SMS</a> na mobil.',
    "Zpět na košík": "Zpět na košík",
    "Zobrazit": "Zobrazit",
    "Od": "Od",
    "Do": "Do",
    "Typ transakcí": "Typ transakcí",
    "Podmínky pro spárování soukromé bankovní karty s kartou Benefit Plus":
        "Podmínky pro spárování soukromé bankovní karty s kartou Benefit Plus",
    "Podmínky jednorázového doplatku soukromou bankovní kartou":
        "Podmínky jednorázového doplatku soukromou bankovní kartou",
    "Rozpis platby: (hodnota objednávky bude odečtena z těchto účtů)":
        "Rozpis platby: (hodnota objednávky bude odečtena z těchto účtů)",
    "Přihlásit pomocí biometriky": "Přihlásit pomocí biometriky",
    "Rozpoznání biometriky se nezdařilo": "Rozpoznání biometriky se nezdařilo",
    "Přihlášení se nezdařilo, nejprve povolte přihlášení biometrikou v nastavení profilu":
        "Přihlášení se nezdařilo, nejprve povolte přihlášení biometrikou v nastavení profilu",
    "Použít biometriku": "Použít biometriku",
    "Zadejte heslo": "Zadejte heslo",
    "Potvrďte heslo": "Potvrďte heslo",
    "Neplatné heslo": "Neplatné heslo",
    "Zadaná hesla se neshodují": "Zadaná hesla se neshodují",
    "Pro ověření potvrďte heslo": "Pro ověření potvrďte heslo",

    "Pro použití biometriky, si prosím nejdříve nastavte heslo":
        "Pro použití biometriky, si prosím nejdříve nastavte heslo",
    "Nastavení": "Nastavení",
    "Povolit Touch ID": "Povolit Touch ID",
    "Povolit Face ID": "Povolit Face ID",
    "Čtečka otisku prstů": "Čtečka otisku prstů",
    "Přidáno do {1}": {phrase: "Přidáno do {1}", 1: ""},
    "oblíbených": "oblíbených",
    "Odebráno z {1}": {phrase: "Odebráno z {1}", 1: ""},
    "Nástěnka": "Nástěnka",
    "Transakce": "Transakce",
    "Hledání": "Hledání",
    "Karta": "Karta",
    "Nenalezeny žádné výsledky": "Nenalezeny žádné výsledky.",
    "Nenalezena žádná karta": "Nenalezena žádná karta",

    "Kategorie": "Kategorie",
    "Filtrovat dle": "Filtrovat dle",
    "Kartou BP lze zaplatit": "Lze zaplatit kartou Benefit Plus",
    "Kartou BP nelze zaplatit": "Nelze zaplatit kartou Benefit Plus",
    "Přidat do oblíbených": "Přidat do oblíbených",
    "Odebrat z oblíbených": "Odebrat z oblíbených",
    "Hledat např. Wellness, Restaurace...": "Hledat např. Wellness, Restaurace...",
    "Seznam benefitů": "Seznam benefitů",
    "Zobrazení": "Zobrazení",
    "Potvrdit": "Potvrdit",
    "Všechny": "Všechny",
    "Informace": "Informace",
    "Kontakty": "Kontakty",
    "Odhlásit": "Odhlásit",
    "Přidat do {1}": {phrase: "Přidat do {1}", 1: ""},
    "Odebrat z {1}": {phrase: "Odebrat z {1}", 1: ""},
    "Oblíbené": "Oblíbené",
    "SMS": "SMS",
    "Hodnocení": "Hodnocení",
    "Navigace": "Navigace",
    "Podskupiny": "Podskupiny",
    "Další filtry": "Další filtry",
    "Seřadit dle vzdálenosti od": "Seřadit dle vzdálenosti od",
    "Informace o obchodním místě": "Informace o obchodním místě",
    "košíku": "košíku",

    "Vítejte v Cafeterii": "Vítejte v Cafeterii",
    'Aplikace Benefit Plus má několik různých modulů. Cafeterie slouží k nákupu daňově zvýhodněných služeb, za které platíte benefitovými body. Pokud jste zde poprvé, podívejte se jak provést objednávku na <a href="{1}" target="_blank">úvodním videu</a>, nebo v <a href="{2}"> Nápovědě</a>.': {
        phrase:
            'Aplikace Benefit Plus má několik různých modulů. Cafeterie slouží k nákupu daňově zvýhodněných služeb, za které platíte benefitovými body. Pokud jste zde poprvé, podívejte se jak provést objednávku na <a href="{1}" target="_blank">úvodním videu</a>, nebo v <a href="{2}"> Nápovědě</a>.',
        1: "",
        2: "",
    },
    "Příště nezobrazovat": "Příště nezobrazovat",
    "Odeslat pomocí SMS na telefon": "Odeslat pomocí SMS na telefon",
    "Stáhnout poukaz": "Stáhnout poukaz",
    "SMS zpráva byla odeslána": "SMS zpráva byla odeslána",
    "E-Stravenky": "E-Stravenky",
    "Text hodnocení": "Text hodnocení",
    "Transakce byla ohodnocena.": "Transakce byla ohodnocena.",
    "Seznam transakcí": "Seznam transakcí",
    "Ohodnotit benefit": "Ohodnotit benefit",
    "Pokračovat v nákupu": "Pokračovat v nákupu",
    "Stáhnout jako Excel tabulku": "Stáhnout jako Excel tabulku",
    "Název benefitu": "Název benefitu",
    "Poskytovatel": "Poskytovatel",
    "Město": "Město",
    "Karta BP": "Karta BP",
    "Přidat do košíku": "Přidat do košíku",
    "Zvolte položku": "Zvolte položku",
    "Přidat soubor": "Přidat soubor",
    "Nové benefity": "Nové benefity",
    "Platba kartou BP": "Platba kartou BP",
    "Odečteme Vám {1}": {phrase: "Odečteme Vám {1}", 1: ""},
    "Aktivace karty se nezdařila": "Aktivace karty se nezdařila",
    "Aktualizace karty se nezdařila": "Aktualizace údajů na kartě se nezdařila",
    "Blokace karty se nezdařila": "Blokace karty se nezdařila",
    "Deaktivace karty se nezdařila": "Deaktivace karty se nezdařila",
    "Objednávka karty se nezdařila": "Objednávka karty se nezdařila",
    "Odblokování karty se nezdařilo": "Odblokování karty se nezdařilo",
    "Nastavení notifikací se neuložilo": "Nastavení notifikací se neuložilo",
    "Nastavení notifikací proběhlo v pořádku": "Nastavení notifikací proběhlo v pořádku",
    "Změna hesla se nezdařila": "Změna hesla se nezdařila",
    "Přidání avataru se nezdařilo": "Přidání avataru se nezdařilo",
    "Vymazání avataru se nezdařilo": "Vymazání avataru se nezdařilo",
    "Vymazání avataru bylo úspěšné": "Vymazání avataru bylo úspěšné",
    "Nahrát": "Nahrát",
    "Profil": "Profil",
    "Hlavní nastavení": "Hlavní nastavení",
    "Ostatní nastavení": "Ostatní nastavení",
    "Sdílet": "Sdílet",
    "Hodnotit": "Hodnotit",
    "Verze": "Verze",
    "Nastavení jazyka": "Nastavení jazyka",
    "Změna jazyka se nepodařila": "Změna jazyka se nepodařila",
    "Jazyk aplikace byl úspěšně změněn": "Jazyk aplikace byl úspěšně změněn",
    "Vyhledávání dle fráze": "Vyhledávání dle fráze",
    "Zde můžete prohledávat seznam benefitů dle Vámi vybraných kriterii.":
        "Zde můžete prohledávat seznam benefitů dle Vámi vybraných kriterii.",
    "Nastala chyba při přihlášení": "Nastala chyba při přihlášení",
    "Aktualizace profilu se nezdařila": "Aktualizace profilu se nezdařila",
    "Aktualizace profilu proběhla úspěšně": "Aktualizace profilu proběhla úspěšně",
    "Karta byla aktualizována": "Karta byla aktualizována",
    "Karta byla odblokována": "Karta byla odblokována",
    "Karta byla zablokována": "Karta byla zablokována",
    "Karta byla aktivována": "Karta byla aktivována",
    "Heslo bylo změněno": "Heslo bylo změněno",
    "Skupiny a podskupiny": "Skupiny a podskupiny",
    "Karta byla odebrána": "Karta byla odebrána",
    "Odebrání karty se nezdařilo": "Odebrání karty se nezdařilo",
    "Karta byla přidána": "Karta byla přidána",
    "Přidání karty se nezdařilo": "Přidání karty se nezdařilo",
    "Zadavatel": "Zadavatel",
    "Startovací adresa": "Startovací adresa",
    "Cílová adresa": "Cílová adresa",
    "Odjezd": "Odjezd",
    "Rozcestník": "Rozcestník",
    "Moje zůstatky": "Moje zůstatky",
    "E-shop": "E-shop",
    "Cafeteria": "Cafeteria",
    "Spolujízda": "Spolujízda",
    "Cestujete do zaměstnání vozem a chcete snížit náklady na dojíždění? Cestujete veřejnou dopravou a rádi byste se s někým svezli? Třeba kolega v jiné kanceláři právě hledá dojíždění nebo nabízí spolujízdu.\n\nZadejte zde svou nabídku na spolujízdu, nebo si prohlédněte již vystavené nabídky. Spolujízda zaručí, že budete vždy jednat s kolegou z Vaší firmy.":
        "Cestujete do zaměstnání vozem a chcete snížit náklady na dojíždění? Cestujete veřejnou dopravou a rádi byste se s někým svezli? Třeba kolega v jiné kanceláři právě hledá dojíždění nebo nabízí spolujízdu.\n\nZadejte zde svou nabídku na spolujízdu, nebo si prohlédněte již vystavené nabídky. Spolujízda zaručí, že budete vždy jednat s kolegou z Vaší firmy.",
    "Přidat inzerát": "Přidat inzerát",
    "Používám aplikaci Benefit Plus a je to paráda! Vyzkoušej to taky a stahuj.":
        "Používám aplikaci Benefit Plus a je to paráda! Vyzkoušej to taky a stahuj.",
    "Upravit inzerát": "Upravit inzerát",
    "Místo": "Místo",
    "Hledat místo": "Hledat místo",
    "Zpráva": "Zpráva",
    "Editovat": "Editovat",
    "Zadejte adresu": "Zadejte adresu",
    "Pouze vlastní inzeráty": "Pouze vlastní inzeráty",
    "Přechází přes": "Přechází přes",
    "Čas odjezdu": "Čas odjezdu",
    "Poznámka": "Poznámka",
    "Uložit": "Uložit",
    "Uložení spolujízdy se nezdařilo": "Uložení spolujízdy se nezdařilo",
    "Uložení spolujízdy proběhlo úspěšně": "Uložení spolujízdy proběhla úspěšně",
    "Aktualizace spolujízdy se nezdařila": "Aktualizace spolujízdy se nezdařila",
    "Aktualizace spolujízdy proběhla úspěšně": "Aktualizace spolujízdy proběhla úspěšně",
    "Mám zájem o spolujízdu": "Mám zájem o spolujízdu",
    "Nová spolujízda": "Nová spolujízda",
    "Upravit spolujízdu": "Upravit spolujízdu",
    "Detail spolujízdy": "Detail spolujízdy",
    "Napište adresu a potvrďte ji kliknutím na položku seznamu":
        "Napište adresu a potvrďte ji kliknutím na položku seznamu",
    "Spolujízda z {1} do {2}": {phrase: "Spolujízda z {1} do {2}", 1: "", 2: ""},
    "Získat v": "Získat v",
    "Stažení PDF se nezdařilo": "Stažení PDF se nezdařilo",
};
