import {createMuiTheme, Theme} from "@material-ui/core";

export function createTheme(): Theme {
    return createMuiTheme({
        palette: {
            primary: {
                dark: "#2490BF",
                main: "#DF2575",
                light: "#EB79AC",
                contrastText: "#fff",
                contrastTextLighter: "rgba(255, 255, 255, .7)",
            },
            secondary: {
                main: "#2C3879",
                light: "#8183B3",
            },
            text: {
                primary: "rgba(0, 0, 0, 0.67)", // normal black text
                secondary: "rgba(127, 127, 127, 1)", // light grey in subcaptions
                tertiary: "rgba(102, 102, 102, 1)", // dark grey in textfields
            },
            paper: {
                light: "#fff",
                main: "#fff",
                dark: "#fff",
                contrastText: "#363636",
            },
        },
        overrides: {
            MuiTypography: {
                body1: {
                    "@media (max-width: 320px)": {
                        fontSize: ".92rem",
                    },
                },
                body2: {
                    lineHeight: 1.25,
                },
                subtitle2: {
                    "@media (max-width: 320px)": {
                        fontSize: ".84rem",
                    },
                    fontWeight: 400,
                },
                h6: {
                    lineHeight: 1.2,
                    fontWeight: 400,
                },
            },
        },
        shape: {
            borderRadius: 16,
        },
        misc: {
            borderRadius: 16,
            background: {
                color: `linear-gradient(90deg, #DF2575 0%, #2C3879 90%)`,
                contrastText: "#ffffff",
            },
            distanceFontSize: 11,
        },
    });
}
